import React, { useState } from "react";
import axios from "../../axios";
import { TRecipe } from "../../types";
import Select from "react-select";

const TAGS = [
  "Meat & Veggies",
  "Family Friendly",
  "Quick & Easy",
  "Pescatarian",
];

interface EditRecipeTagsProps {
  recipe: TRecipe;
  refetch: () => Promise<void>;
}

const EditRecipeTags: React.FC<EditRecipeTagsProps> = ({ recipe, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [addingMode, setAddingMode] = useState(false);
  const [tagsOptions, setTagsOptions] = useState<string[]>(TAGS);
  const [addedTags, setAddedTags] = useState<string[]>([]);

  // useEffect(() => {
  //   axios
  //     .get(`/api/categories`)
  //     .then((response) => setTagsOptions(response.data.nodes || []));
  // }, []);

  const onConfirmRemove = async (tag: string) => {
    setLoading(true);
    const newTags = (recipe.tags || [])?.filter((t) => t !== tag);
    await axios(`/api/recipes/${recipe.id}`, {
      method: "PUT",
      data: { tags: newTags },
    });
    await refetch();
    setSaved(true);
    setLoading(false);
    setTimeout(() => setSaved(false), 3000);
  };

  const onAdd = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!addedTags.length) return;
    setLoading(true);
    const newTags = [...(recipe.tags || []), ...addedTags];
    const uniqueTags = Array.from(new Set(newTags));
    await axios(`/api/recipes/${recipe.id}`, {
      method: "PUT",
      data: { tags: uniqueTags },
    });
    await refetch();
    setAddedTags([]);
    setSaved(true);
    setAddingMode(false);
    setLoading(false);
    setTimeout(() => setSaved(false), 3000);
  };

  const onRemoveClick = (categoryId: string) => {
    if (window.confirm("This cannot be undone, are you sure?") === true) {
      onConfirmRemove(categoryId);
    }
  };

  // if (!recipe?.categories.length) {
  //   return <h1 className="title">There is no Tags for this recipe</h1>;
  // }

  return (
    <div>
      <h2 className="title">You are editing tags for "{recipe.name}"</h2>
      <h1 className="title">
        {saved ? "Saved" : loading ? "Loading..." : " "}
      </h1>

      <br />

      {(recipe.tags || []).map((tag) => (
        <div key={tag}>
          <p>{tag}</p>
          <button
            type="button"
            className="delete-button"
            onClick={() => onRemoveClick(tag)}
          >
            remove
          </button>
        </div>
      ))}

      <br />
      <div>
        <button
          type="button"
          className="update-button"
          onClick={() => setAddingMode((prev) => !prev)}
        >
          Add one more category for this recipe
        </button>

        <br />
        <br />
        {addingMode && (
          <form onSubmit={onAdd} className="form" style={{ maxWidth: "100%" }}>
            <label className="label">Tags:</label>
            <Select
              isMulti
              name="tags"
              className="select"
              onChange={(selectedOptions) =>
                setAddedTags(selectedOptions.map((o) => o.value))
              }
              options={tagsOptions.map((option) => ({
                value: option,
                label: option,
              }))}
              value={addedTags?.map((tag) => ({
                value: tag,
                label: tag,
              }))}
            />
            <button disabled={saved || loading} className="submit-button">
              Save
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditRecipeTags;
