const timeRegex =
  /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+\.?\d*)S)?)?/;

function encodeMinutesToISO8601(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let iso8601Duration = "PT";

  if (hours > 0) {
    iso8601Duration += `${hours}H`;
  }

  if (remainingMinutes > 0) {
    iso8601Duration += `${remainingMinutes}M`;
  }

  return iso8601Duration;
}

export function decodeISO8601(time: string) {
  const matches = time.match(timeRegex);
  if (!matches) {
    throw new Error(`Invalid ISO8601 duration string: ${time}`);
  }

  const years = parseInt(matches[1] ?? "0");
  const months = parseInt(matches[2] ?? "0");
  const weeks = parseInt(matches[3] ?? "0");
  const days = parseInt(matches[4] ?? "0");
  const hours = parseInt(matches[5] ?? "0");
  const minutes = parseInt(matches[6] ?? "0");
  const seconds = parseFloat(matches[7] ?? "0");

  return {
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds,
  };
}

export function parseTimeToMins(time: string) {
  const temp = decodeISO8601(time);
  return temp.hours * 60 + temp.minutes;
}

export function parseMinsToTime(time: number) {
  const temp = encodeMinutesToISO8601(time);
  return temp;
}
