import React, { useEffect, useState } from "react";
import axios from "../shared/axios";
import { PaginatedResponse, TUser } from "../shared/types";
import { getDateString } from "../shared/utils";

import "./Clients.css";
import { Link } from "react-router-dom";

interface ClientsProps {}

const Clients: React.FC<ClientsProps> = ({}) => {
  const pageSize = 20; // number of users per page
  const [currentPage, setCurrentPage] = useState(1);
  const [clients, setData] = useState<PaginatedResponse<TUser>>({
    nodes: [],
    totalCount: 0,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      endCursor: "",
    },
  });

  useEffect(() => {
    axios(
      `/api/users?first=${pageSize}&after=${(currentPage - 1) * pageSize}`
    ).then((response) => {
      setData(response.data);
    });
  }, [currentPage]);

  const totalPages = Math.ceil(clients.totalCount / pageSize);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="client-container">
      <h1>Clients</h1>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>SignUp At</th>
            <th>Orders</th>
          </tr>
        </thead>
        <tbody>
          {clients?.nodes?.map((client) => {
            const createdAt = new Date(client.createdAt);
            return (
              <tr key={client.id}>
                <td>{client.id}</td>
                <td>{client.name}</td>
                <td>{client.email}</td>
                <td>{client.phone}</td>
                <td>
                  {new Date(client.createdAt).toLocaleDateString(
                    "en-GB"
                    // {
                    //   year: "numeric",
                    //   day: "numeric",
                    //   month: "short",
                    // }
                  )}
                </td>
                <td>
                  <Link to={`/clients/${client.id}/orders`}>Orders</Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => setCurrentPage(pageNumber)}
            className={pageNumber === currentPage ? "active" : ""}
          >
            {pageNumber}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Clients;
