// Header.js
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../shared/axios";
import Sidebar from "./Sidebar";
import "./Dashboard.css";
import md5 from "js-md5";

interface HeaderProps {
  email: string;
}

export const Header: React.FC<HeaderProps> = ({ email }) => {
  let navigate = useNavigate();
  const gravatarUrl = `https://www.gravatar.com/avatar/${md5(
    email.trim().toLowerCase()
  )}`;

  const handleLogout = () => {
    axios
      .put(`/auth/logout`)
      .then(() => {
        navigate("/");
        window.location.reload();
      })
      .catch((error) => console.error("Error during logout:", error));
  };

  return (
    <>
      <Link className="logo-icon" to="/clients">
        <img
          src="https://canary-freshkit-assets.s3.eu-central-1.amazonaws.com/642617f817e7147c6959e781_Freshkit+(1).png"
          className="logo"
          alt="logo"
          width={100}
        />
      </Link>
      <header className="side-bar">
        <Sidebar />

        <img
          className="logout-button"
          onClick={handleLogout}
          src={gravatarUrl}
          alt="User avatar"
        />
      </header>
    </>
  );
};
