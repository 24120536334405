import React, { useState, ChangeEvent, FormEvent } from "react";
import "./Ingredient.css";
import axios from "../shared/axios";

export enum Locale {
  EN = "en",
  AR = "ar",
}
export enum IngredientType {
  ADD_ON = "ADD_ON",
  BASE = "BASE",
  TOPPING = "TOPPING",
  SAUCE = "SAUCE",
  DRESSING = "DRESSING",
  SPICE = "SPICE",
  OTHER = "OTHER",
}

export interface IngredientTranslationDto {
  name: string;
  slug: string;
  unit?: string;
  locale: Locale;
}

export interface CreateIngredientDto {
  name: string;
  slug: string;
  imageSrc?: string;
  imageCaption?: string;
  translations: IngredientTranslationDto[];

  type?: IngredientType;
  unit?: string;
  measurement?: string;
  price?: {
    amount: number;
    currency: string;
  };
}

const IngredientCreationForm: React.FC = () => {
  const [formData, setFormData] = useState<CreateIngredientDto>({
    type: IngredientType.BASE,
    name: "",
    imageSrc: "",
    imageCaption: "",
    slug: "",
    translations: [],
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleTranslationChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const newTranslations = [...formData.translations];
    newTranslations[index] = {
      ...newTranslations[index],
      [event.target.name]: event.target.value,
    };
    setFormData({
      ...formData,
      translations: newTranslations,
    });
  };

  const handleAddTranslation = () => {
    setFormData({
      ...formData,
      translations: [
        ...formData.translations,
        { name: "", slug: "", locale: Locale.AR },
      ],
    });
  };

  const handleRemoveTranslation = (index: number) => {
    setFormData({
      ...formData,
      translations: formData.translations.filter((_, i) => i !== index),
    });
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    axios
      .post(`/api/ingredients`, {
        name: formData.name,
        slug: formData.slug,
        images: [
          {
            src: formData.imageSrc,
            caption: formData.imageCaption,
          },
        ],
        translations: [
          {
            name: formData.translations[0].name,
            slug: formData.translations[0].slug,
            locale: formData.translations[0].locale,
            unit: formData.translations[0].unit,
          },
        ],
        ...(formData.type === IngredientType.ADD_ON && {
          type: formData.type,
          unit: formData.unit,
          measurement: formData.measurement,
          price: {
            amount: Number(formData.price),
            currency: "EGP",
          },
        }),
      })
      .then((response) => {
        // console.log("Ingredient created:", response.data);
        window.location.reload();
      })
      .catch((error) => console.error("Error creating ingredient:", error));
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <h1>
        <span className="title">Create Ingredient</span>
      </h1>
      <label className="label">Name:</label>
      <input
        type="text"
        name="name"
        onChange={handleChange}
        className="input"
      />
      <label className="label">Slug:</label>
      <input
        type="text"
        name="slug"
        onChange={handleChange}
        className="input"
      />
      <div
        className="images"
        style={{
          border: "1px solid ",
          borderColor: "#e2d2d2",
          borderRadius: "5px",
          padding: "5px",
          width: "90%",
          margin: "0px 0px 10px 5px ",
        }}
      >
        <label className="label">src:</label>
        <input
          type="text"
          name="imageSrc"
          onChange={handleChange}
          className="input"
        />
        <label className="label">caption:</label>
        <input
          type="text"
          name="imageCaption"
          onChange={handleChange}
          className="input"
        />
      </div>
      <label>type:</label>
      <select name="type" onChange={handleChange} className="select">
        <option value="" hidden>
          Select Type
        </option>
        <option value={IngredientType.ADD_ON}>ADD_ON</option>
        <option value={IngredientType.BASE}>BASE</option>
        <option value={IngredientType.TOPPING}>TOPPING</option>
        <option value={IngredientType.SAUCE}>SAUCE</option>
        <option value={IngredientType.DRESSING}>DRESSING</option>
        <option value={IngredientType.SPICE}>SPICE</option>
        <option value={IngredientType.OTHER}>OTHER</option>
      </select>
      {formData.type === IngredientType.ADD_ON && (
        <div>
          <label>unit:</label>
          <input type="text" name="unit" onChange={handleChange} />
          <label>measurement:</label>
          <input type="text" name="measurement" onChange={handleChange} />
          <label>price:</label>
          <input type="text" name="price" onChange={handleChange} />
        </div>
      )}
      <label>Translation</label>
      {formData.translations.map((translation, index) => (
        <div key={index} className="translation-block">
          <label className="label">Name:</label>
          <input
            type="text"
            name="name"
            value={translation.name}
            onChange={(event) => handleTranslationChange(index, event)}
            className="input"
          />
          <label className="label">Slug:</label>
          <input
            type="text"
            name="slug"
            value={translation.slug}
            onChange={(event) => handleTranslationChange(index, event)}
            className="input"
          />
          {formData.type === IngredientType.ADD_ON && (
            <div>
              <label>unit:</label>
              <input
                value={translation.unit}
                type="text"
                name="unit"
                onChange={handleChange}
              />
            </div>
          )}
          <label className="label">Locale:</label>
          <select
            name="locale"
            value={translation.locale}
            onChange={(event) => handleTranslationChange(index, event)}
            className="select"
          >
            <option value={Locale.AR}>AR</option>
          </select>
          <button
            type="button"
            onClick={() => handleRemoveTranslation(index)}
            className="remove-button"
          >
            Remove
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddTranslation}
        className="add-button"
      >
        Add Translation
      </button>
      <button type="submit" className="submit-button">
        Submit
      </button>
    </form>
  );
};

export { IngredientCreationForm as Ingredients };
