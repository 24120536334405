import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { CategoryDto, TRecipe } from "../../types";
import Select from "react-select";

interface EditRecipeCategoriesProps {
  recipe: TRecipe;
  refetch: () => Promise<void>;
}

const EditRecipeCategories: React.FC<EditRecipeCategoriesProps> = ({
  recipe,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [addingMode, setAddingMode] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<CategoryDto[]>([]);
  const [addedCategoriesIds, setAddedCategoriesIds] = useState<string[]>([]);

  useEffect(() => {
    axios
      .get(`/api/categories`)
      .then((response) => setCategoryOptions(response.data.nodes || []));
  }, []);

  const onConfirmRemove = async (categoryId: string) => {
    setLoading(true);
    const newCategories = recipe.categories
      .filter((c) => c.id !== categoryId)
      .map((c) => c.id);
    await axios(`/api/recipes/${recipe.id}`, {
      method: "PUT",
      data: { categories: newCategories },
    });
    await refetch();
    setSaved(true);
    setLoading(false);
    setTimeout(() => setSaved(false), 3000);
  };

  const onRemoveClick = (categoryId: string) => {
    if (window.confirm("This cannot be undone, are you sure?") === true) {
      onConfirmRemove(categoryId);
    }
  };

  const onAdd = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!addedCategoriesIds.length) return;
    setLoading(true);
    const newCategories = [
      ...recipe.categories.map((c) => c.id),
      ...addedCategoriesIds,
    ];
    const uniqueIds = Array.from(new Set(newCategories));
    await axios(`/api/recipes/${recipe.id}`, {
      method: "PUT",
      data: { categories: uniqueIds },
    });
    await refetch();
    setAddedCategoriesIds([]);
    setSaved(true);
    setAddingMode(false);
    setLoading(false);
    setTimeout(() => setSaved(false), 3000);
  };

  // if (!recipe?.categories.length) {
  //   return <h1 className="title">There is no Categories for this recipe</h1>;
  // }

  return (
    <div>
      <h2 className="title">You are editing categories for "{recipe.name}"</h2>
      <h1 className="title">
        {saved ? "Saved" : loading ? "Loading..." : " "}
      </h1>

      <br />

      {recipe.categories.map((category) => (
        <div key={category.id}>
          <p>{category.name}</p>
          <button
            type="button"
            className="delete-button"
            onClick={() => onRemoveClick(category.id)}
          >
            remove
          </button>
        </div>
      ))}

      <br />
      <div>
        <button
          type="button"
          className="update-button"
          onClick={() => setAddingMode((prev) => !prev)}
        >
          Add one more category for this recipe
        </button>

        <br />
        <br />
        {addingMode && (
          <form onSubmit={onAdd} className="form" style={{ maxWidth: "100%" }}>
            <label className="label">Categories:</label>
            <Select
              isMulti
              name="categories"
              className="select"
              onChange={(selectedOptions) =>
                setAddedCategoriesIds(selectedOptions.map((o) => o.value))
              }
              options={categoryOptions.map((option) => ({
                value: option.id,
                label: option.name,
              }))}
              value={addedCategoriesIds?.map((categoryId) => ({
                value: categoryId,
                label: categoryOptions.find(
                  (option) => option.id === categoryId
                )?.name,
              }))}
            />
            <button disabled={saved || loading} className="submit-button">
              Save
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditRecipeCategories;
