// Sidebar.js
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  FaUser,
  FaList,
  FaRegListAlt,
  FaSeedling,
  FaUtensils,
  FaSearch,
  FaBookOpen,
  FaWrench,
  FaGift,
  FaListAlt,
} from "react-icons/fa";
import Tooltip from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const Sidebar = () => {
  return (
    <nav className="nav-bar">
      <StyledNavLink to="/search">
        <Tooltip content="Search Clients" placement="right">
          <span className="nav-bar-icons">
            <FaSearch />
          </span>
        </Tooltip>
      </StyledNavLink>
      <StyledNavLink to="/clients">
        <Tooltip content="List Clients" placement="right">
          <span className="nav-bar-icons">
            <FaUser />
          </span>
        </Tooltip>
      </StyledNavLink>
      <StyledNavLink to="/orders">
        <Tooltip content="List Orders" placement="right">
          <span className="nav-bar-icons">
            <FaListAlt />
          </span>
        </Tooltip>
      </StyledNavLink>
      <StyledNavLink to="/recipe">
        <Tooltip content="Create Recipe" placement="right">
          <span className="nav-bar-icons">
            <FaBookOpen />
          </span>
        </Tooltip>
      </StyledNavLink>
      <StyledNavLink to="/recipes">
        <Tooltip content="List Recipe" placement="right">
          <span className="nav-bar-icons">
            <FaBookOpen />
            <FaList />
          </span>
        </Tooltip>
      </StyledNavLink>
      <StyledNavLink to="/category">
        <Tooltip content="Create Category" placement="right">
          <span className="nav-bar-icons">
            <FaRegListAlt />
          </span>
        </Tooltip>
      </StyledNavLink>
      <StyledNavLink to="/ingredients">
        <Tooltip content="Create Ingredients" placement="right">
          <span>
            <FaSeedling />
          </span>
        </Tooltip>
      </StyledNavLink>
      <StyledNavLink to="/listIngredients">
        <Tooltip content="List Ingredients" placement="right">
          <span className="nav-bar-icons">
            <FaSeedling />
            <FaList />
          </span>
        </Tooltip>
      </StyledNavLink>
      <StyledNavLink to="/utensils">
        <Tooltip content="Create Utensils" placement="right">
          <span className="nav-bar-icons">
            <FaUtensils />
          </span>
        </Tooltip>
      </StyledNavLink>
      <StyledNavLink to="/promoCodes">
        <Tooltip content="Create Promo Codes" placement="right">
          <span className="nav-bar-icons">
            <FaGift />
          </span>
        </Tooltip>
      </StyledNavLink>
      <StyledNavLink to="/settings">
        <Tooltip content="Settings" placement="right">
          <span className="nav-bar-icons">
            <FaWrench />
          </span>
        </Tooltip>
      </StyledNavLink>
    </nav>
  );
};

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 10px;
  color: #000;
  text-decoration: none;
  border: 1px solid #000;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 20px;
  background-color: #12d232;
  :hover {
    color: #fff;
    background-color: #12d232;
  }
  &.active {
    color: #fff;
    background-color: #c1edc9;
  }
`;

export default Sidebar;
