import React, { useEffect, useState } from "react";
import axios from "../shared/axios";
import { PaginatedResponse, TUser } from "../shared/types";

import "./searchClients.css";
import { Link } from "react-router-dom";

export const SearchPage: React.FC = () => {
  const pageSize = 20; // number of users per page
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [clients, setData] = useState<PaginatedResponse<TUser>>({
    nodes: [],
    totalCount: 0,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      endCursor: "",
    },
  });
  useEffect(() => {
    const filter = search ? JSON.stringify({ search: search }) : "";
    axios(
      `/api/users?first=${pageSize}&after=${(currentPage - 1) * pageSize}${
        filter ? `&filter=${filter}` : ""
      }`
    ).then((response) => {
      setData(response.data);
    });
  }, [search, currentPage]); // Added dependency array here

  const totalPages = Math.ceil(clients.totalCount / pageSize);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="client-container">
      <div className="searchBar">
        <input
          type="text"
          className="searchInput"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>SignUp At</th>
            <th>Orders</th>
          </tr>
        </thead>
        <tbody>
          {clients?.nodes?.map((client) => (
            <tr key={client.id}>
              <td>{client.id}</td>
              <td>{client.name}</td>
              <td>{client.email}</td>
              <td>{client.phone}</td>
              <td>{client.createdAt}</td>
              <td>
                <Link to={`/clients/${client.id}/orders`}>Orders</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => setCurrentPage(pageNumber)}
            className={pageNumber === currentPage ? "active" : ""}
          >
            {pageNumber}
          </button>
        ))}
      </div>
    </div>
  );
};
