import React, { useState, useEffect } from "react";
import axios from "../shared/axios";
import "./Settings.css";
import { FaInfinity } from "react-icons/fa";

const Settings: React.FC = () => {
  const [weeklyLimit, setWeeklyLimit] = useState<number | null>(null);
  const [currentLimit, setCurrentLimit] = useState<number | null>(null);
  const [periods, setPeriods] = useState<any[]>([]);
  const [ordersThisWeek, setOrdersThisWeek] = useState<number>(0);
  useEffect(() => {
    axios.get("/api/orders/weeklyLimit").then((response) => {
      const { maxOrdersPerWeek, periods, ordersThisWeek } = response.data;
      setCurrentLimit(maxOrdersPerWeek);
      setWeeklyLimit(maxOrdersPerWeek);
      setPeriods(periods.splice(1));
      setOrdersThisWeek(ordersThisWeek);
    });
  }, []);

  const handleUpdateLimit = () => {
    axios
      .put("/api/orders/weeklyLimit", { maxOrdersPerWeek: weeklyLimit })
      .then((res) => {
        setWeeklyLimit(res.data.maxOrdersPerWeek);
        setPeriods(res.data.periods.splice(1));
        setCurrentLimit(res.data.maxOrdersPerWeek);
        setOrdersThisWeek(res.data.ordersThisWeek);
        alert("Limit updated successfully!✅");
      })
      .catch((error) => {
        console.error("Error updating weekly limit:", error);
        alert("Error updating weekly limit!⚠️❌");
      });
  };

  return (
    <div className="settings-container">
      <h1 className="settings-header">Settings</h1>
      <div>
        <h2>
          Current Weekly Order Limit:{" "}
          {weeklyLimit === -1 ? "Unlimited" : weeklyLimit}
        </h2>
        <h3>
          This Week's Order Limit:{" "}
          {weeklyLimit === -1 ? "Unlimited" : weeklyLimit}
        </h3>
        <h3>
          Orders This Week So far: {ordersThisWeek} /{" "}
          {weeklyLimit === -1 ? "Unlimited" : weeklyLimit}
        </h3>
        <label className="settings-label">
          Set New Weekly Order Limit:
          {/* add note under the input field that says "Enter -1 for unlimited" */}
          <input
            type="number"
            className="settings-input"
            value={weeklyLimit || ""}
            onChange={(e) => setWeeklyLimit(Number(e.target.value))}
            about="Enter -1 for unlimited"
            // on Wheel blur the field
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
          />
          <span className="settings-note" style={{ color: "red" }}>
            Enter <b>-1</b> for unlimited
          </span>
        </label>
        <button onClick={handleUpdateLimit} className="settings-button">
          Update Limit
        </button>
        <br />
        <details style={{ marginTop: "10px", cursor: "pointer" }}>
          <summary>View Weekly Order Limit History</summary>
          <table className="settings-table">
            <thead>
              <tr>
                <th>Week</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Max Orders</th>
              </tr>
            </thead>
            {periods.map((period, index) => {
              return (
                <tbody key={period.id}>
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {new Date(period.startPeriod).toLocaleDateString(
                        "en-US",
                        {
                          weekday: "short",
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }
                      )}
                    </td>
                    <td>
                      {new Date(period.endPeriod).toLocaleDateString("en-US", {
                        weekday: "short",
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        timeZone: "UTC",
                      })}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {period.maxOrdersThisWeek === -1 ? (
                        <FaInfinity />
                      ) : (
                        period.maxOrdersThisWeek
                      )}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </details>
      </div>
    </div>
  );
};

export default Settings;
