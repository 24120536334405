import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Login } from "./screens/Login";
import { Recipe } from "./screens/Recipe";
import { Category } from "./screens/Category";
import { Utensil } from "./screens/Utensils";
import { Header } from "./screens/Dashboard";
import { Ingredients } from "./screens/Ingredients";
import axios from "./shared/axios";
import Orders from "./screens/Orders";
import Clients from "./screens/Clients";
import ClientOrders from "./screens/ClientOrders";
import { SearchPage } from "./screens/searchClients";
import Settings from "./screens/Settings";
import IngredientsPage from "./screens/listIngredients";
// import RecipePage from "./screens/RecipesPage";
import PromoCodesPage from "./screens/PromoCodesPage";
import RecipesPage from "./screens/RecipesPage";

function App() {
  const [user, setUser] = useState({
    email: "",
    name: "",
    phone: "",
    id: "",
  });
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/api/users/me`)
      .then((response) => {
        setUser(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setUser({ email: "", name: "", phone: "", id: "" });
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Router>
      <Header email={user?.email || ""} />
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to="/settings" /> : <Login />}
        />
        <Route
          path="/recipe"
          element={user ? <Recipe /> : <Navigate to="/" />}
        />
        <Route
          path="/category"
          element={user ? <Category /> : <Navigate to="/" />}
        />
        <Route
          path="/utensils"
          element={user ? <Utensil /> : <Navigate to="/" />}
        />
        <Route
          path="/ingredients"
          element={user ? <Ingredients /> : <Navigate to="/" />}
        />
        <Route
          path="/orders"
          element={user ? <Orders /> : <Navigate to="/" />}
        />
        <Route
          path="/clients"
          element={user ? <Clients /> : <Navigate to="/" />}
        />
        <Route
          path="/clients/:id/orders"
          element={user ? <ClientOrders /> : <Navigate to="/" />}
        />
        <Route
          path="/search"
          element={user ? <SearchPage /> : <Navigate to="/" />}
        />
        <Route
          path="/settings"
          element={user ? <Settings /> : <Navigate to="/" />}
        />
        <Route
          path="/listIngredients"
          element={user ? <IngredientsPage /> : <Navigate to="/" />}
        />
        <Route
          path="/recipes"
          element={user ? <RecipesPage /> : <Navigate to="/" />}
        />
        <Route
          path="/promoCodes"
          element={user ? <PromoCodesPage /> : <Navigate to="/" />}
        />
        <Route path="*" element={<Navigate to="/settings" />} />
      </Routes>
    </Router>
  );
}

export default App;
