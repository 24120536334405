// Import necessary libraries and types
import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import "./Recipe.css";
import Select, { MultiValue } from "react-select";
import axios from "../shared/axios";

export interface IngredientInfo {
  info: string; // reference to Ingredient
  value: number;
  value2: number;
  unit: string;
}
export interface Nutrition {
  type?: string;
  name: string;
  amount: number;
  unit: string;
}

const PREFERENCES = [
  {
    value: "Meat & Veggies",
    label: "Meat & Veggies",
  },
  {
    value: "Family Friendly",
    label: "Family Friendly",
  },
  {
    value: "Quick & Easy",
    label: "Quick & Easy",
  },
  {
    value: "Pescatarian",
    label: "Pescatarian",
  },
];

export interface Price {
  amount: number;
  currencyCode: string;
}

export interface Seo {
  name: string;
  description?: string;
}

export interface Calories {
  value: number;
  unit: string;
}

export interface IMoney {
  amount: number;
  currency: string;
}
export interface CookingInstructionTranslation {
  index: number;
  text: string;
}
export interface RecipeTranslation {
  name: string;
  description: string;
  seo: Seo;
  locale: string;
}
export interface Image {
  src: string;
  caption?: string;
}
interface FormData {
  name: string;
  status: RecipeStatusEnum;
  slug: string;
  description: string;
  prepTime: string;
  cookingTime: string;
  amount: number;
  seoName: string;
  seoDescription: string;
  caloriesValue: number;
  caloriesUnit: string;
  cookingDifficulty: CookingDifficulty;
  imageSrc: string;
  imageCaption: string;
  tags?: string[];
  nutrition: Nutrition[];
  categories: string[];
  ingredients: IngredientInfo[];
  videoLink: string;
  cardLink: string;
  cookingInstructions: CookingInstructionDto[];
  utensils: string[];
  translations?: RecipeTranslation[];
}
export interface CookingInstructionDto {
  index: number;
  text: string;
  ingredients: string[];
  utensils: string[];
  timers?: string;
  instructionImageSrc?: string;
  instructionImageCaption?: string;
  translations: CookingInstructionTranslation[];
}
export enum RecipeStatusEnum {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}
export enum CookingDifficulty {
  VERY_EASY = "VERY_EASY",
  EASY = "EASY",
  MEDIUM = "MEDIUM",
  HARD = "HARD",
  VERY_HARD = "VERY_HARD",
}

const Recipe: React.FC = () => {
  // State variables for the form fields
  const [formData, setFormData] = useState<FormData>({
    name: "",
    description: "",
    status: RecipeStatusEnum.DRAFT,
    prepTime: "",
    cookingTime: "",
    amount: 0,
    seoName: "",
    seoDescription: "",
    caloriesValue: 0,
    caloriesUnit: "KCAL",
    cookingDifficulty: CookingDifficulty.VERY_EASY,
    imageSrc: "",
    imageCaption: "",
    tags: [],
    nutrition: [],
    videoLink: "",
    cardLink: "",
    cookingInstructions: [],
    slug: "",
    categories: [],
    ingredients: [],
    utensils: [],
    translations: [],
    // Add other form fields here...
  });

  // State variables for the select field options
  const [ingredientOptions, setIngredientOptions] = useState<{
    nodes: any[];
  }>({
    nodes: [],
  });
  const [categoryOptions, setCategoryOptions] = useState<{
    nodes: any[];
  }>({
    nodes: [],
  });
  const [utensilOptions, setUtensilOptions] = useState<{
    nodes: any[];
  }>({
    nodes: [],
  });
  const [cookingInstructions, setCookingInstructions] = useState<
    CookingInstructionDto[]
  >([]);
  const [ingred, setIngred] = useState<IngredientInfo[]>([]);
  const [nutrition, setNutrition] = useState<Nutrition[]>([]);
  const cookingInstructionTranslation = {
    index: 0,
    text: "",
  };
  const [cookingInstructionTranslations, setCookingInstructionTranslations] =
    useState<CookingInstructionTranslation[]>([cookingInstructionTranslation]);

  const handleInstructionTranslationChange = (
    index: number,
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const newTranslations = [...cookingInstructionTranslations];
    newTranslations[index] = {
      ...newTranslations[index],
      [event.target.name]: event.target.value,
    };
    setCookingInstructionTranslations(newTranslations);
  };

  const handleRemoveInstructionTranslation = (index: number) => {
    const newTranslations = [...cookingInstructionTranslations];
    newTranslations.splice(index, 1);
    setCookingInstructionTranslations(newTranslations);
  };

  const recipeTranslation = {
    name: "",
    description: "",
    seo: {
      name: "",
      description: "",
    },
    locale: "ar",
  };

  const [translations, setTranslations] = useState<RecipeTranslation[]>([
    recipeTranslation,
  ]);

  const handleTranslationChange = (
    index: number,
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const newTranslations = [...translations];
    newTranslations[index] = {
      ...newTranslations[index],
      [event.target.name]: event.target.value,
      ...(event.target.name.startsWith("seo")
        ? {
            seo: {
              ...newTranslations[index].seo,
              [event.target.name.replace("seo", "").toLowerCase()]:
                event.target.value,
            },
          }
        : {}),
    };
    setTranslations(newTranslations);
  };

  const handleAddTranslation = () => {
    setTranslations([
      ...translations,
      {
        name: "",
        description: "",
        seo: {
          name: "",
          description: "",
        },
        locale: "ar",
      },
    ]);
    console.log(translations);
  };

  const handleRemoveTranslation = (index: number) => {
    const newTranslations = [...translations];
    newTranslations.splice(index, 1);
    setTranslations(newTranslations);
  };

  // Handle changes to the multi-select ingredients field
  const handleIngredientChange = (
    index: number,
    event: { target: { name: string; value: any } }
  ) => {
    const newIngredients = [...ingred];
    newIngredients[index] = {
      ...newIngredients[index],
      [event.target.name]: event.target.value,
    };
    setIngred(newIngredients);
  };

  const handleAddIngredient = () => {
    setIngred([
      ...ingred,
      {
        info: "",
        value: 0,
        value2: 0,
        unit: "",
      },
    ]);
  };
  const handleRemoveIngredient = (index: number) => {
    const newIngredients = [...ingred];
    newIngredients.splice(index, 1);
    setIngred(newIngredients);
  };
  const handleNutritionChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const newNutrition = [...nutrition];
    newNutrition[index] = {
      ...newNutrition[index],
      [event.target.name]: event.target.value,
    };
    setNutrition(newNutrition);
  };
  const handleAddNutrition = () => {
    setNutrition([
      ...nutrition,
      {
        type: "",
        name: "",
        amount: 0,
        unit: "",
      },
    ]);
  };
  const handleRemoveNutrition = (index: number) => {
    const newNutrition = [...nutrition];
    newNutrition.splice(index, 1);
    setNutrition(newNutrition);
  };
  const handleAddInstruction = () => {
    setCookingInstructions([
      ...cookingInstructions,
      {
        index: cookingInstructions.length,
        text: "",
        ingredients: [],
        utensils: [],
        timers: "",
        instructionImageCaption: "",
        instructionImageSrc: "",
        translations: [],
      },
    ]);
  };
  const handleRemoveInstruction = (index: number) => {
    const newInstructions = [...cookingInstructions];
    newInstructions.splice(index, 1);
    setCookingInstructions(newInstructions);
  };
  // const handleInstructionChange = (
  //   index: number,
  //   event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  // ) => {
  //   const newInstructions = [...cookingInstructions];
  //   newInstructions[index] = {
  //     ...newInstructions[index],
  //     [event.target.name]: event.target.value,
  //   };
  //   setCookingInstructions(newInstructions);
  // };

  const handleInstructionChange = (
    index: number,
    event: { target: { name: string; value: any } }
  ) => {
    const newInstructions = [...cookingInstructions];
    newInstructions[index] = {
      ...newInstructions[index],
      [event.target.name]: event.target.value,
    };
    setCookingInstructions(newInstructions);
  };

  // Fetch the options for the select fields when the component mounts
  useEffect(() => {
    // Fetch the ingredients
    axios
      .get(`/api/ingredients`)
      .then((response) => setIngredientOptions(response.data))
      .catch((error) => console.error("Error fetching ingredients:", error));

    // Fetch the categories
    axios
      .get(`/api/categories`)
      .then((response) => setCategoryOptions(response.data))
      .catch((error) => console.error("Error fetching categories:", error));

    // Fetch the utensils
    axios
      .get(`/api/utensils`)
      .then((response) => setUtensilOptions(response.data))
      .catch((error) => console.error("Error fetching utensils:", error));
  }, []);

  // Handle changes to the form fields
  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  // Handle changes to the multi-select categories field
  const handleMultiSelectChange = (
    fieldName: string,
    event: MultiValue<{
      value: string;
      label: string;
    }>
  ) => {
    setFormData({
      ...formData,
      [fieldName]: event.map((option) => option.value),
    });
  };

  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     cookingInstructions: cookingInstructions,
  //   });
  // }, [cookingInstructions, formData]);
  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     ingredients: ingred,
  //   });
  // }, [ingred, formData]);
  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     nutrition: nutrition,
  //   });
  // }, [nutrition, formData]);
  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     tags: formData.tags,
  //   });
  // }, [formData.tags, formData]);
  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     utensils: formData.utensils,
  //   });
  // }, [formData.utensils, formData]);

  // Handle form submission
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    // Post the form data to the API
    console.log({
      ...formData,
      cookingInstructions: cookingInstructions,
      ingredients: ingred,
      nutrition: nutrition,
      tags: formData.tags,
      utensils: formData.utensils,
    });
    axios
      .post(`/api/recipes`, {
        ...formData,
        cookingInstructions: cookingInstructions,
        ingredients: ingred.map((ingredient) => ({
          info: ingredient.info,
          quantity: [
            {
              value: ingredient.value,
              numberOfServings: 2,
            },
            {
              value: ingredient.value2,
              numberOfServings: 4,
            },
          ],
          unit: ingredient.unit,
        })),
        nutrition: nutrition,
        tags: formData.tags,
        utensils: formData.utensils,
        images: [
          {
            src: formData.imageSrc,
            caption: formData.imageCaption,
          },
        ],
        calories: {
          value: formData.caloriesValue,
          unit: formData.caloriesUnit,
        },
        price: {
          amount: formData.amount,
          currency: "EGP",
        },
        seo: {
          name: formData.seoName,
          description: formData.seoDescription,
        },
        translations,
      })
      .then((response) => {
        // console.log("Recipe created:", response.data);
        window.location.reload();
      })
      .catch((error) => console.error("Error creating recipe:", error));
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <h1>
        <span className="title">Create Recipe</span>
      </h1>
      <label className="label">Name:</label>
      <input
        type="text"
        name="name"
        onChange={handleChange}
        className="input"
        value={formData.name}
      />
      <label className="label">Slug:</label>
      <input
        type="text"
        name="slug"
        onChange={handleChange}
        className="input"
        value={formData.slug}
      />
      <label className="label">Description:</label>
      <textarea name="description" onChange={handleChange} className="input" />
      <label className="label">Status:</label>
      <select
        name="status"
        onChange={handleChange}
        className="select"
        value={formData.status}
      >
        <option value={RecipeStatusEnum.DRAFT}>Draft</option>
        <option value={RecipeStatusEnum.ACTIVE}>Active</option>
        <option value={RecipeStatusEnum.ARCHIVED}>Archived</option>
      </select>
      <label className="label">PrepTime:</label>
      <input
        type="text"
        name="prepTime"
        onChange={handleChange}
        className="input"
        placeholder="PT1H30M"
        value={formData.prepTime}
      />
      <label className="label">CookTime:</label>
      <input
        type="text"
        name="cookingTime"
        onChange={handleChange}
        className="input"
        placeholder="PT1H30M"
        value={formData.cookingTime}
      />
      <label className="label">Price:</label>
      <input
        type="number"
        onWheel={(event) => event.currentTarget.blur()}
        name="amount"
        onChange={handleChange}
        className="input"
        placeholder="1.99"
        value={formData.amount}
      />
      <label className="label">Seo:</label>
      <div
        className="seo"
        style={{
          border: "1px solid ",
          borderColor: "#e2d2d2",
          borderRadius: "5px",
          padding: "5px",
          width: "100%",
          margin: "0px 0px 10px 5px ",
        }}
      >
        <label className="label">name:</label>
        <input
          type="text"
          name="seoName"
          onChange={handleChange}
          className="input"
          value={formData.seoName}
        />
        <label className="label">description:</label>
        <textarea
          name="seoDescription"
          onChange={handleChange}
          className="input"
          value={formData.seoDescription}
        />
      </div>
      <label className="label">calories:</label>
      <div
        className="seo"
        style={{
          border: "1px solid ",
          borderColor: "#e2d2d2",
          borderRadius: "5px",
          padding: "5px",
          width: "100%",
          margin: "0px 0px 10px 5px ",
        }}
      >
        <label className="label">value:</label>
        <input
          type="number"
          onWheel={(event) => event.currentTarget.blur()}
          name="caloriesValue"
          onChange={handleChange}
          className="input"
          value={formData.caloriesValue}
        />
        <label className="label">unit:</label>
        <input
          type="text"
          name="caloriesUnit"
          onChange={handleChange}
          className="input"
          value={formData.caloriesUnit}
        />
      </div>
      <label className="label">Tags:</label>
      <Select
        isMulti
        name="tags"
        className="select"
        onChange={(selectedOptions) =>
          handleMultiSelectChange("tags", selectedOptions)
        }
        options={PREFERENCES}
        value={formData.tags?.map((tag) => ({
          value: tag,
          label: tag,
        }))}
      />
      <label className="label">
        CookingDifficulty:
        <select
          name="cookingDifficulty"
          onChange={handleChange}
          className="select"
          value={formData.cookingDifficulty}
        >
          <option value="">Select CookingDifficulty</option>
          <option value={CookingDifficulty.VERY_EASY}>Very Easy</option>
          <option value={CookingDifficulty.EASY}>Easy</option>
          <option value={CookingDifficulty.MEDIUM}>Medium</option>
          <option value={CookingDifficulty.HARD}>Hard</option>
          <option value={CookingDifficulty.VERY_HARD}>Very Hard</option>
        </select>
      </label>
      <label className="label">cardLink:</label>
      <input
        type="text"
        name="cardLink"
        onChange={handleChange}
        className="input"
        value={formData.cardLink}
      />
      <label className="label">videoLink:</label>
      <input
        type="text"
        name="videoLink"
        onChange={handleChange}
        className="input"
        value={formData.videoLink}
      />
      <label className="label">Image:</label>
      <div
        className="images"
        style={{
          border: "1px solid ",
          borderColor: "#e2d2d2",
          borderRadius: "5px",
          padding: "5px",
          width: "100%",
          margin: "0px 0px 10px 5px ",
        }}
      >
        <label className="label">src:</label>
        <input
          type="text"
          name="imageSrc"
          onChange={handleChange}
          className="input"
          value={formData.imageSrc}
        />
        <label className="label">caption:</label>
        <input
          type="text"
          name="imageCaption"
          onChange={handleChange}
          className="input"
          value={formData.imageCaption}
        />
      </div>
      <label className="label">Categories:</label>
      <Select
        isMulti
        name="categories"
        className="select"
        onChange={(selectedOptions) =>
          handleMultiSelectChange("categories", selectedOptions)
        }
        options={categoryOptions.nodes.map((option) => ({
          value: option.id,
          label: option.name,
        }))}
        value={formData.categories?.map((category) => ({
          value: category,
          label: categoryOptions.nodes.find((option) => option.id === category)
            ?.name,
        }))}
      />

      <label className="label">Utensils:</label>
      <Select
        isMulti
        name="utensils"
        className="select"
        onChange={(selectedOptions) =>
          handleMultiSelectChange("utensils", selectedOptions)
        }
        options={utensilOptions.nodes.map((option) => ({
          value: option.id,
          label: option.name,
        }))}
        value={formData.utensils?.map((utensil) => ({
          value: utensil,
          label: utensilOptions.nodes.find((option) => option.id === utensil)
            ?.name,
        }))}
      />
      <label className="label">Nutrition:</label>
      {nutrition.map((nutrient, index) => (
        <div key={index} className="nutrition">
          <label>Type:</label>
          <input
            type="text"
            name="type"
            onChange={(event) => handleNutritionChange(index, event)}
            value={nutrient.type}
          />

          <label>Name:</label>
          <input
            type="text"
            name="name"
            onChange={(event) => handleNutritionChange(index, event)}
            value={nutrient.name}
          />
          <label>Amount:</label>
          <input
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            name="amount"
            onChange={(event) => handleNutritionChange(index, event)}
            value={nutrient.amount}
          />
          <label>Unit:</label>
          <input
            type="text"
            name="unit"
            onChange={(event) => handleNutritionChange(index, event)}
            value={nutrient.unit}
          />
          <button type="button" onClick={() => handleRemoveNutrition(index)}>
            Remove
          </button>
        </div>
      ))}
      <button type="button" onClick={handleAddNutrition}>
        Add Nutrition
      </button>
      <br></br>
      <label className="label">Ingredients:</label>
      {ingred.map((ingredient, index) => (
        <div key={index} className="ingredients">
          <label>Info:</label>
          <Select
            name="info"
            value={{
              value: ingredient.info,
              label: ingredientOptions.nodes.find(
                (option) => option.id === ingredient.info
              )?.name,
            }}
            onChange={(selectedOption) =>
              handleIngredientChange(index, {
                target: {
                  name: "info",
                  value: selectedOption?.value,
                },
              })
            }
            options={ingredientOptions.nodes.map((option) => ({
              value: option.id,
              label: option.name,
            }))}
          />
          <label>Quantity:</label>
          <label className="label">
            2 People
            <input
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              name="value"
              onChange={(event) => handleIngredientChange(index, event)}
              value={ingredient.value}
            />
          </label>
          <label className="label">
            4 people
            <input
              type="number"
              onWheel={(event) => event.currentTarget.blur()}
              name="value2"
              onChange={(event) => handleIngredientChange(index, event)}
              value={ingredient.value2}
            />
          </label>
          <label>Unit:</label>
          <input
            type="text"
            name="unit"
            onChange={(event) => handleIngredientChange(index, event)}
            value={ingredient.unit}
          />
          <button type="button" onClick={() => handleRemoveIngredient(index)}>
            Remove
          </button>
        </div>
      ))}
      <button type="button" onClick={handleAddIngredient}>
        Add Ingredient
      </button>
      <label className="label">CookingInstructions:</label>
      {cookingInstructions.map((instruction, index) => (
        <div key={index} className="instructions">
          <label>Index:</label>
          <input
            type="number"
            onWheel={(event) => event.currentTarget.blur()}
            name="index"
            value={instruction.index}
            onChange={(event) => handleInstructionChange(index, event)}
          />

          <label>Details:</label>
          <textarea
            // style={{ blockSize: "10rem", width: "200%" }}
            name="text"
            value={instruction.text}
            onChange={(event) => handleInstructionChange(index, event)}
          />
          <div
            className="images"
            style={{
              border: "1px solid ",
              borderColor: "#e2d2d2",
              borderRadius: "5px",
              padding: "5px",
              width: "100%",
              margin: "0px 0px 10px 5px ",
            }}
          >
            <label className="label">src:</label>
            <input
              type="text"
              name="instructionImageSrc"
              onChange={handleChange}
              className="input"
              value={instruction.instructionImageSrc}
            />
            <label className="label">caption:</label>
            <input
              type="text"
              name="instructionImageCaption"
              onChange={handleChange}
              className="input"
              value={instruction.instructionImageCaption}
            />
          </div>
          <br></br>
          <label>
            Ingredients:
            <Select
              isMulti
              name="ingredients"
              value={instruction.ingredients.map((ingredientId) => {
                const ingredient = ingredientOptions.nodes.find(
                  (option) => option.id === ingredientId
                );
                return {
                  value: ingredientId,
                  label: ingredient ? ingredient.name : "",
                };
              })}
              onChange={(selectedOptions) =>
                handleInstructionChange(index, {
                  target: {
                    name: "ingredients",
                    value: selectedOptions.map((option) => option.value),
                  },
                })
              }
              options={ingredientOptions.nodes.map((option) => ({
                value: option.id,
                label: option.name,
              }))}
            />
          </label>
          <label>
            Utensils:
            <Select
              isMulti
              name="utensils"
              value={instruction.utensils.map((utensilId) => ({
                value: utensilId,
                label:
                  utensilOptions.nodes.find((option) => option.id === utensilId)
                    ?.name || "",
              }))}
              onChange={(selectedOptions) =>
                handleInstructionChange(index, {
                  target: {
                    name: "utensils",
                    value: selectedOptions.map((option) => option.value),
                  },
                })
              }
              options={utensilOptions.nodes.map((option) => ({
                value: option.id,
                label: option.name,
              }))}
            />
          </label>
          <label>Timers:</label>
          <input
            type="text"
            name="timers"
            placeholder="PT1H30M"
            onChange={(event) => handleInstructionChange(index, event)}
            value={instruction.timers}
          />
          <br></br>
          <label className="label"> Translations:</label>
          {cookingInstructionTranslations.map((translation, index) => (
            <div key={index} className="translations">
              <label>Index:</label>
              <input
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
                name="index"
                value={translation.index}
                onChange={(event) =>
                  handleInstructionTranslationChange(index, event)
                }
              />
              <label>Text:</label>
              <textarea
                name="text"
                value={translation.text}
                onChange={(event) =>
                  handleInstructionTranslationChange(index, event)
                }
              />
            </div>
          ))}
          <br></br>

          <button type="button" onClick={() => handleRemoveInstruction(index)}>
            Remove
          </button>
        </div>
      ))}
      <button type="button" onClick={handleAddInstruction}>
        Add Instruction
      </button>
      <label className="label">Translations:</label>
      {translations?.map((translation, index) => (
        <div key={index} className="translations">
          <label>Name:</label>
          <input
            type="text"
            name="name"
            onChange={(event) => handleTranslationChange(index, event)}
            value={translation.name}
          />
          <label>Description:</label>
          <textarea
            name="description"
            onChange={(event) => handleTranslationChange(index, event)}
            value={translation.description}
          />
          <label>Seo:</label>
          <div
            className="seo"
            style={{
              border: "1px solid ",
              borderColor: "#e2d2d2",
              borderRadius: "5px",
              padding: "5px",
              width: "100%",
              margin: "0px 0px 10px 5px ",
            }}
          >
            <label className="label">name:</label>
            <input
              type="text"
              name="seoName"
              onChange={(event) => handleTranslationChange(index, event)}
              className="input"
              value={translation.seo.name}
            />
            <label className="label">description:</label>
            <textarea
              name="seoDescription"
              onChange={(event) => handleTranslationChange(index, event)}
              className="input"
              value={translation.seo.description}
            />
          </div>
        </div>
      ))}
      <button type="button" onClick={handleAddTranslation}>
        Add Translation
      </button>
      <br></br>
      <button type="submit" className="submit-button">
        Submit
      </button>
    </form>
  );
};

export { Recipe };
