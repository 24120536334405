import React, { useEffect } from "react";

import "./EditorModal.css";

interface EditorModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

const EditorModal: React.FC<EditorModalProps> = ({
  children,
  open,
  onClose,
}) => {
  useEffect(() => {
    function handleKeyupEvent(event: any) {
      if (event.key === "Escape") onClose();
    }
    if (open) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keyup", handleKeyupEvent);
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keyup", handleKeyupEvent);
    };
  }, [onClose, open]);

  return (
    <>
      {open && <div className="editor-modal_backdrop" onClick={onClose} />}
      <div className={`editor-modal_wrapper ${open && "open"}`}>{children}</div>
    </>
  );
};

export default EditorModal;
