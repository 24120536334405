import React, { useEffect, useState } from "react";
import { IngredientDto, IngredientInfoDto, TRecipe } from "../../types";
import EditRecipeIngredientForm from "./EditRecipeIngredientForm";
import axios from "../../axios";
import Select from "react-select";

interface EditRecipeIngredientsProps {
  recipe: TRecipe;
  defaultIngredient?: IngredientInfoDto;
  ingredients: IngredientInfoDto[];
  refetch: () => Promise<void>;
}

const EditRecipeIngredients: React.FC<EditRecipeIngredientsProps> = ({
  recipe,
  ingredients,
  defaultIngredient,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [addingMode, setAddingMode] = useState(false);
  const [ingredientOptions, setIngredientOptions] = useState<IngredientDto[]>(
    []
  );
  const [addedIngredientsIds, setAddedIngredientsIds] = useState<string[]>([]);

  useEffect(() => {
    axios
      .get(`/api/ingredients`)
      .then((response) => setIngredientOptions(response.data.nodes || []));
  }, []);

  const initial = defaultIngredient || ingredients[0];
  const [slice, setSlice] = useState(
    ingredients.findIndex((i) => i.info.id === initial.info.id)
  );
  const currentEditingIngredient = ingredients[slice];

  const onConfirmRemove = async () => {
    setLoading(true);
    const newIngredients = ingredients
      .filter((i) => i.info.id !== currentEditingIngredient.info.id)
      .map((i) => ({ ...i, info: i.info.id }));
    await axios(`/api/recipes/${recipe.id}`, {
      method: "PUT",
      data: { ingredients: newIngredients },
    });
    await refetch();
    setSlice(0);
    setSaved(true);
    setLoading(false);
    setTimeout(() => setSaved(false), 3000);
  };

  const onRemoveClick = () => {
    if (window.confirm("This cannot be undone, are you sure?") === true) {
      onConfirmRemove();
    }
  };

  const onAdd = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!addedIngredientsIds.length) return;
    setLoading(true);
    const newIngredients = [
      ...recipe.ingredients.map((i) => i.info.id),
      ...addedIngredientsIds,
    ];
    const uniqueIds = Array.from(new Set(newIngredients));
    await axios(`/api/recipes/${recipe.id}`, {
      method: "PUT",
      data: {
        ingredients: uniqueIds.map((i) => ({
          info: i,
          unit: "ml",
          quantity: [
            { numberOfServings: 2, value: 20 },
            { numberOfServings: 4, value: 40 },
          ],
        })),
      },
    });
    await refetch();
    setAddedIngredientsIds([]);
    setSaved(true);
    setAddingMode(false);
    setLoading(false);
    setTimeout(() => setSaved(false), 3000);
  };

  // if (!currentEditingIngredient) {
  //   return <h1 className="title">There is no Ingredients for this recipe</h1>;
  // }

  return (
    <div>
      <h2 className="title">You are editing ingredients for "{recipe.name}"</h2>
      {currentEditingIngredient && (
        <h2 className="title">
          {slice + 1}- {currentEditingIngredient?.info.name}
        </h2>
      )}
      <h1 className="title">
        {saved ? "saved" : loading ? "Loading..." : " "}
      </h1>

      {currentEditingIngredient && (
        <div>
          <button
            type="button"
            className="update-button"
            onClick={() => {
              setSlice((prev) =>
                prev === 0 ? ingredients.length - 1 : prev - 1
              );
            }}
          >
            Previous
          </button>
          <button
            type="button"
            className="update-button"
            onClick={() => {
              setSlice((prev) =>
                prev === ingredients.length - 1 ? 0 : prev + 1
              );
            }}
          >
            Next
          </button>
        </div>
      )}
      <br />

      {currentEditingIngredient && (
        <EditRecipeIngredientForm
          key={currentEditingIngredient.info.id}
          ingredient={currentEditingIngredient}
          ingredients={ingredients}
          recipe={recipe}
          refetch={refetch}
        />
      )}

      <br />
      <div>
        {currentEditingIngredient && (
          <button
            type="button"
            className="delete-button"
            onClick={onRemoveClick}
          >
            Delete this ingredient from this recipe
          </button>
        )}
        <button
          type="button"
          className="update-button"
          onClick={() => setAddingMode(true)}
        >
          Add one more ingredient for this recipe
        </button>
      </div>

      <br />
      <br />
      {addingMode && (
        <form onSubmit={onAdd} className="form" style={{ maxWidth: "100%" }}>
          <label className="label">Ingredients:</label>
          <Select
            isMulti
            name="ingredients"
            className="select"
            onChange={(selectedOptions) =>
              setAddedIngredientsIds(selectedOptions.map((o) => o.value))
            }
            options={ingredientOptions.map((option) => ({
              value: option.id,
              label: option.name,
            }))}
            value={addedIngredientsIds?.map((categoryId) => ({
              value: categoryId,
              label: ingredientOptions.find(
                (option) => option.id === categoryId
              )?.name,
            }))}
          />
          <button disabled={saved || loading} className="submit-button">
            Save
          </button>
        </form>
      )}
    </div>
  );
};

export default EditRecipeIngredients;
