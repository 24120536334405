import React, { useState } from "react";
import { CookingDifficulty, RecipeStatusEnum, TRecipe } from "../../types";
import { parseMinsToTime, parseTimeToMins } from "../../utils/parse-time";
import axios from "../../axios";

interface FormValues {
  name: string;
  cookingTime: string;
  cookingDifficulty: string;
  price: string;
  slug: string;
  image: string;
}

interface EditRecipeFormProps {
  recipe: TRecipe;
  refetch: () => Promise<void>;
}

const EditRecipeForm: React.FC<EditRecipeFormProps> = ({ recipe, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.target as HTMLFormElement);
    const formValues = Object.fromEntries(formData) as unknown as FormValues;
    await axios(`/api/recipes/${recipe.id}`, {
      method: "PUT",
      data: {
        name: formValues.name,
        price: { amount: formValues.price, currency: "EGP" },
        cookingTime: parseMinsToTime(Number(formValues.cookingTime)),
        cookingDifficulty: formValues.cookingDifficulty,
        slug: formValues.slug,
        images: [{ src: formValues.image, caption: "" }],
      },
    });
    refetch();
    setSaved(true);
    setLoading(false);
    setTimeout(() => setSaved(false), 3000);
  };

  return (
    <form onSubmit={handleSubmit} className="form" style={{ maxWidth: "100%" }}>
      <h1 className="title">
        {saved ? "Saved" : loading ? "Loading..." : "Edit Recipe"}
      </h1>
      <label className="label">Name:</label>
      <input
        type="text"
        name="name"
        className="input"
        defaultValue={recipe.name}
      />
      <label className="label">Arabic Name:</label>
      <input
        dir="rtl"
        type="text"
        name="nameAr"
        className="input"
        defaultValue={recipe?.translations?.[0]?.name}
      />
      <label className="label">Recipe Status:</label>
      <select name="status" className="select" defaultValue={recipe.status}>
        {Object.values(RecipeStatusEnum).map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select>
      <label className="label">Price:</label>
      <input
        type="text"
        name="price"
        className="input"
        defaultValue={recipe.price.amount}
      />
      <label className="label">Cooking Time in minutes:</label>
      <input
        type="text"
        name="cookingTime"
        className="input"
        defaultValue={parseTimeToMins(recipe.cookingTime)}
      />
      <label className="label">Difficulty:</label>
      <select
        name="cookingDifficulty"
        className="select"
        defaultValue={recipe.cookingDifficulty}
      >
        <option value={CookingDifficulty.EASY}>EASY</option>
        <option value={CookingDifficulty.MEDIUM}>MEDIUM</option>
        <option value={CookingDifficulty.HARD}>HARD</option>
      </select>
      <label className="label">Slug:</label>
      <input
        type="text"
        name="slug"
        className="input"
        defaultValue={recipe.slug}
      />
      <label className="label">Image:</label>
      <input
        type="text"
        name="image"
        className="input"
        defaultValue={recipe?.images[0]?.src}
      />
      <button disabled={saved || loading} className="submit-button">
        Save
      </button>
    </form>
  );
};

export default EditRecipeForm;
