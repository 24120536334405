import React, { useState } from "react";
import "./Login.css";
import axios from "../shared/axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  let navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    formError: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    formError: "",
  });

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    });
  };

  const validateForm = () => {
    let formIsValid = true;
    const errors: {
      email: string;
      password: string;
      formError: string;
    } = {
      email: "",
      password: "",
      formError: "",
    };

    if (!credentials.email || credentials.email.trim() === "") {
      formIsValid = false;
      errors["email"] = "Please enter a email.";
    }

    if (!credentials.password || credentials.password.trim() === "") {
      formIsValid = false;
      errors["password"] = "Please enter a password.";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios(`/auth/admin/login`, {
        data: credentials,
        method: "POST",
      });
      navigate("/");
      window.location.reload();
    } catch (error: any) {
      console.error(error?.response.data.message);
      setErrors({
        ...errors,
        formError: error?.response.data.message,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="login-form-container">
      <header>Log In</header>
      <label>
        email:
        <input
          type="text"
          name="email"
          onChange={handleInputChange}
          value={credentials.email}
          required
        />
        {errors.email && <div>{errors.email}</div>}
      </label>
      <label>
        Password:
        <input
          type="password"
          name="password"
          onChange={handleInputChange}
          value={credentials.password}
          required
        />
      </label>
      <button className="login" type="submit">
        Log in
      </button>
      {errors.formError && <div className="form-error">{errors.formError}</div>}
    </form>
  );
};

export { Login };
