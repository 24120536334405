import React, { useState, useEffect } from "react";
import axios from "../shared/axios";
import {
  IngredientCategory,
  IngredientDto,
  PaginatedResponse,
} from "../shared/types";
import { IngredientType } from "./Ingredients";
import "./listIngredients.css";

const IngredientsPage: React.FC = () => {
  const [data, setData] = useState<PaginatedResponse<IngredientDto>>({
    nodes: [],
    totalCount: 0,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      endCursor: "",
    },
  });
  const [search, setSearch] = useState<string>("");
  const [category, setCategory] = useState<IngredientCategory | "All">(
    "All" as any
  );
  const [ingredients, setIngredients] = useState<IngredientDto[]>([]);
  const [initialIngredients, setInitialIngredients] = useState<IngredientDto[]>(
    []
  );
  const [newIngredient, setNewIngredient] = useState<IngredientDto | null>(
    null
  );

  useEffect(() => {
    const filter = JSON.stringify({
      ...(search && { search: search }),
      ...(category !== "All" && { category: category }),
    });

    axios
      .get(`/api/ingredients?${filter ? `filter=${filter}` : ""}`)
      .then((response) => {
        // sort the ingredients by category, category is a name
        const sortedIngredients = response.data.nodes.sort(
          // category
          (a: IngredientDto, b: IngredientDto) =>
            (b.category || "").localeCompare(a.category || "") ||
            // type
            // a.type.localeCompare(b.type) ||
            // name
            a.name.localeCompare(b.name)
        );
        setData(response.data);
        setIngredients(sortedIngredients);
        setInitialIngredients(sortedIngredients); // Store the initial data
      });
  }, [search, category]);

  const handleUpdate = (ingredientId: string) => {
    const ingredientToUpdate = ingredients.find(
      (ingredient) => ingredient.id === ingredientId
    );
    const mappedIngredient = {
      unit: ingredientToUpdate?.unit,
      measurement: ingredientToUpdate?.measurement,
      ...(ingredientToUpdate?.price?.amount && {
        price: {
          amount: ingredientToUpdate?.price?.amount,
          currency: "EGP",
        },
      }),
      type: ingredientToUpdate?.type,
      name: ingredientToUpdate?.name,
      slug: ingredientToUpdate?.slug,
      marketingName: ingredientToUpdate?.marketingName,
      brand: ingredientToUpdate?.brand,
      category: ingredientToUpdate?.category,
      onHand: Number(ingredientToUpdate?.onHand),
    };
    if (ingredientToUpdate) {
      axios
        .put(`/api/ingredients/${ingredientId}`, mappedIngredient)
        .then((response) => {
          const updatedIngredient = response.data as IngredientDto;
          return setIngredients((prevState) =>
            prevState.map((ingredient) => {
              if (ingredient.id === updatedIngredient.id) {
                return updatedIngredient;
              }
              return ingredient;
            })
          );
        });
    }
  };

  const handleInputChange = (
    ingredientId: string,
    key: keyof IngredientDto,
    value: any
  ) => {
    setIngredients((prevState) =>
      prevState.map((ingredient) => {
        if (ingredient.id === ingredientId) {
          return { ...ingredient, [key]: value };
        }
        return ingredient;
      })
    );
  };
  const hasChanged = (ingredientId: string) => {
    const initialIngredient = initialIngredients.find(
      (ingredient) => ingredient.id === ingredientId
    );
    const currentIngredient = ingredients.find(
      (ingredient) => ingredient.id === ingredientId
    );
    return (
      JSON.stringify(currentIngredient) !== JSON.stringify(initialIngredient)
    );
  };
  return (
    <div className="ingredients-container">
      <h1 className="ingredients-header">Ingredients</h1>
      <div className="searchBar">
        <input
          type="text"
          className="searchInput"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <select
          className="ingredient-category"
          defaultValue="Select Category"
          onChange={(e) => {
            const newCategory = e.target.value as IngredientCategory | "All";
            setCategory(newCategory);
            if (newCategory === "All") {
              setIngredients(initialIngredients);
            } else {
              setIngredients(
                initialIngredients.filter(
                  (ingredient) => ingredient.category === newCategory
                )
              );
            }
          }}
        >
          <option disabled>Select Category</option>
          <option value="All">All</option>
          {Object.values(IngredientCategory).map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <table className="ingredients-table">
        <thead
          style={{
            position: "sticky",
            top: 0,
          }}
        >
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Marketing Name</th>
            <th>Slug</th>
            <th>Type</th>
            <th>Brand</th>
            <th>Category</th>
            <th>Unit</th>
            <th>Measurement</th>
            <th>On Hand</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {ingredients.map((ingredient, index) => (
            <tr key={ingredient.id}>
              <td>{index + 1}</td>
              <td>
                <input
                  value={ingredient.name}
                  onChange={(e) =>
                    handleInputChange(ingredient.id, "name", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  value={ingredient.marketingName || ""}
                  onChange={(e) =>
                    handleInputChange(
                      ingredient.id,
                      "marketingName",
                      e.target.value
                    )
                  }
                />
              </td>
              <td>
                <input
                  value={ingredient.slug}
                  onChange={(e) =>
                    handleInputChange(ingredient.id, "slug", e.target.value)
                  }
                />
              </td>
              <td>
                <select
                  className="ingredient-type"
                  defaultValue={ingredient.type ? ingredient.type : "Select"}
                  onChange={(e) => {
                    const newType = e.target.value as IngredientType;
                    setIngredients((prevState) =>
                      prevState.map((ingr) =>
                        ingr.id === ingredient.id
                          ? { ...ingr, type: newType }
                          : ingr
                      )
                    );
                  }}
                >
                  <option disabled>Select</option>
                  {Object.values(IngredientType).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  value={ingredient.brand || ""}
                  onChange={(e) =>
                    handleInputChange(ingredient.id, "brand", e.target.value)
                  }
                />
              </td>
              <td>
                <select
                  value={ingredient.category || "Select"}
                  onChange={(e) =>
                    handleInputChange(ingredient.id, "category", e.target.value)
                  }
                >
                  <option disabled>Select</option>
                  {Object.values(IngredientCategory).map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  value={ingredient.unit || ""}
                  onChange={(e) =>
                    handleInputChange(ingredient.id, "unit", e.target.value)
                  }
                >
                  <option disabled>Select</option>
                  <option value="kg">kg</option>
                  <option value="gram">gram</option>
                  <option value="liter">liter</option>
                  <option value="ml">ml</option>
                  <option value="sachet">sachet</option>
                  <option value="piece">piece</option>
                </select>
              </td>
              <td>
                <input
                  value={ingredient.measurement || ""}
                  onChange={(e) =>
                    handleInputChange(
                      ingredient.id,
                      "measurement",
                      e.target.value
                    )
                  }
                />
              </td>
              <td>
                <input
                  value={ingredient.onHand || ""}
                  onChange={(e) =>
                    handleInputChange(ingredient.id, "onHand", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  value={ingredient.price?.amount || ""}
                  onChange={(e) =>
                    handleInputChange(ingredient.id, "price", {
                      ...ingredient.price,
                      amount: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <button
                  onClick={() => handleUpdate(ingredient.id)}
                  className="update-button"
                  disabled={!hasChanged(ingredient.id)}
                >
                  Update
                </button>
              </td>
            </tr>
          ))}
          {/* another row to add ingredients with its fields */}
          <tr>
            <td>#</td>
            <td>
              <input
                type="text"
                className="add-ingredient-input"
                placeholder="Name"
                onChange={(e) => {
                  const newName = e.target.value;
                  setNewIngredient(
                    (prevState) =>
                      ({ ...prevState, name: newName } as IngredientDto)
                  );
                }}
              />
            </td>
            <td>
              <input
                type="text"
                className="add-ingredient-input"
                placeholder="Marketing Name"
                onChange={(e) => {
                  const newMarketingName = e.target.value;
                  setNewIngredient(
                    (prevState) =>
                      ({
                        ...prevState,
                        marketingName: newMarketingName,
                      } as IngredientDto)
                  );
                }}
              />
            </td>
            <td>
              <input
                type="text"
                className="add-ingredient-input"
                placeholder="Slug"
                onChange={(e) => {
                  const newSlug = e.target.value;
                  setNewIngredient(
                    (prevState) =>
                      ({ ...prevState, slug: newSlug } as IngredientDto)
                  );
                }}
              />
            </td>
            <td>
              <select
                className="ingredient-type"
                defaultValue="Select"
                onChange={(e) => {
                  const newType = e.target.value as IngredientType;
                  setNewIngredient(
                    (prevState) =>
                      ({ ...prevState, type: newType } as IngredientDto)
                  );
                }}
              >
                <option disabled>Select</option>
                {Object.values(IngredientType).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </td>
            <td>
              <input
                type="text"
                className="add-ingredient-input"
                placeholder="Brand"
                onChange={(e) => {
                  const newBrand = e.target.value;
                  setNewIngredient(
                    (prevState) =>
                      ({ ...prevState, brand: newBrand } as IngredientDto)
                  );
                }}
              />
            </td>
            <td>
              <select
                className="ingredient-category"
                defaultValue="Select Category"
                onChange={(e) => {
                  const newCategory = e.target.value as IngredientCategory;
                  setNewIngredient(
                    (prevState) =>
                      ({ ...prevState, category: newCategory } as IngredientDto)
                  );
                }}
              >
                <option disabled>Select Category</option>
                {Object.values(IngredientCategory).map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </td>
            <td>
              <select
                className="ingredient-unit"
                defaultValue="Select"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  setNewIngredient(
                    (prevState) =>
                      ({ ...prevState, unit: newUnit } as IngredientDto)
                  );
                }}
              >
                <option disabled>Select</option>
                <option value="kg">kg</option>
                <option value="gram">gram</option>
                <option value="liter">liter</option>
                <option value="ml">ml</option>
                <option value="sachet">sachet</option>
                <option value="piece">piece</option>
              </select>
            </td>
            <td>
              <input
                type="number"
                className="add-ingredient-input"
                placeholder="Measurement"
                onChange={(e) => {
                  const newMeasurement = e.target.value;
                  setNewIngredient(
                    (prevState) =>
                      ({
                        ...prevState,
                        measurement: Number(newMeasurement),
                      } as IngredientDto)
                  );
                }}
              />
            </td>
            <td>
              <input
                type="number"
                className="add-ingredient-input"
                placeholder="On Hand"
                onChange={(e) => {
                  const newOnHand = Number(e.target.value);
                  setNewIngredient(
                    (prevState) =>
                      ({ ...prevState, onHand: newOnHand } as IngredientDto)
                  );
                }}
              />
            </td>
            <td>
              <input
                type="number"
                className="add-ingredient-input"
                placeholder="Price"
                onChange={(e) => {
                  const newPrice = e.target.value;
                  setNewIngredient(
                    (prevState) =>
                      ({
                        ...prevState,
                        price: { amount: newPrice, currency: "EGP" },
                      } as unknown as IngredientDto)
                  );
                }}
              />
            </td>
            <td>
              <button
                className="add-ingredient-button"
                style={{
                  cursor: "pointer",
                  margin: 0,
                  backgroundColor: "#4CAF50",
                  color: "white",
                  padding: "10px 10px",
                  border: "none",
                  borderRadius: "4px",
                  fontSize: "16px",
                }}
                onClick={() => {
                  axios.post("/api/ingredients", newIngredient).then(() => {
                    setIngredients((prevState) => [
                      ...prevState,
                      newIngredient as IngredientDto,
                    ]);
                    setInitialIngredients((prevState) => [
                      ...prevState,
                      newIngredient as IngredientDto,
                    ]);
                    setNewIngredient(null);
                  });
                }}
              >
                Create
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default IngredientsPage;
