import React, { useEffect, useState } from "react";
import axios from "../shared/axios";
import {
  OrderPaymentStatusEnum,
  OrderShippingStatusEnum,
  OrderStatusEnum,
  PaginatedResponse,
  TOrder,
} from "../shared/types";

import "./Order.css";
import _ from "lodash";
import {
  getCurrentWeekStartEnd,
  getPastWeekStartEnd,
} from "../shared/utils/computeDate";
import ShoppingListDialog from "./shopping-list-dialog";

interface OrdersProps {}
interface OrderUpdate {
  id: string;
  status: OrderStatusEnum;
  shippingStatus: OrderShippingStatusEnum;
  paymentStatus: OrderPaymentStatusEnum;
  message: string;
}
const Orders: React.FC<OrdersProps> = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showShoppingListDialog, setShowShoppingListDialog] = useState(false);
  const [data, setData] = useState<PaginatedResponse<TOrder>>({
    nodes: [],
    totalCount: 0,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      endCursor: "",
    },
  });
  const [showAll, setShowAll] = useState(true);
  const [showPastWeek, setShowPastWeek] = useState(false);
  const [showCurrentWeek, setShowCurrentWeek] = useState(false);
  const [pageSize, setPageSize] = useState(20);

  const [orderUpdates, setOrderUpdates] = useState<OrderUpdate[]>([]);

  let filters = JSON.stringify({
    ...(showPastWeek && { pastWeek: true }),
    ...(showCurrentWeek && { currentWeek: true }),
  });

  useEffect(() => {
    axios(
      `/api/orders/?first=${pageSize}&offset=${(currentPage - 1) * pageSize}&${
        !_.isEmpty(filters) ? `filter=${filters}` : ""
      }`
    ).then((response) => {
      const ordersData = response.data?.nodes;
      setData(response.data);
      const initialOrderUpdates = ordersData.map((order: TOrder) => ({
        id: order.id,
        status: order.status,
        shippingStatus: order.shippingStatus,
        paymentStatus: order.paymentStatus,
        message: "",
      }));
      setOrderUpdates(initialOrderUpdates);
    });
  }, [currentPage, filters, pageSize]);

  const handleUpdate = (id: string) => {
    const orderUpdate = orderUpdates.find((update) => update.id === id);
    axios
      .put(`/api/orders/${id}`, {
        status: orderUpdate?.status,
        shippingStatus: orderUpdate?.shippingStatus,
        paymentStatus: orderUpdate?.paymentStatus,
      })
      .then(() => {
        setOrderUpdates((prev) =>
          prev.map((update) =>
            update.id === id
              ? { ...update, message: "Update successful!" }
              : update
          )
        );
      })
      .catch(() => {
        setOrderUpdates((prev) =>
          prev.map((update) =>
            update.id === id
              ? { ...update, message: "Update failed. Please try again." }
              : update
          )
        );
      });
  };

  const { weekStart, weekEnd } = getCurrentWeekStartEnd(new Date());
  const { pastWeekStart, pastWeekEnd } = getPastWeekStartEnd(new Date());
  const totalPages = Math.ceil(data.totalCount / pageSize);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div style={{ padding: "40px 20px" }} className="orders-div">
      <button
        onClick={() => {
          setShowAll((prev) => !prev);
          setShowPastWeek(false);
          setShowCurrentWeek(false);
        }}
        className="order-show-button"
        style={!showAll ? { backgroundColor: "grey" } : {}}
      >
        Show All Orders
      </button>
      <button
        onClick={() => {
          setShowPastWeek((prev) => !prev);
          setShowAll(false);
          setShowCurrentWeek(false);
        }}
        className="order-show-button"
        style={!showPastWeek ? { backgroundColor: "grey" } : {}}
      >
        Show Past Week Orders
      </button>
      <button
        onClick={() => {
          setShowCurrentWeek((prev) => !prev);
          setShowAll(false);
          setShowPastWeek(false);
        }}
        className="order-show-button"
        style={!showCurrentWeek ? { backgroundColor: "grey" } : {}}
      >
        Show This Week Orders
      </button>
      <h4>
        {showAll
          ? "All Orders"
          : showCurrentWeek
          ? `This Week Orders from ${new Date(weekStart).toLocaleDateString(
              "en-US",
              {
                weekday: "short",
                day: "2-digit",
                month: "short",
                year: "numeric",
              }
            )} to ${new Date(weekEnd).toLocaleDateString("en-US", {
              weekday: "short",
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}`
          : showPastWeek
          ? `Past Week Orders from ${new Date(pastWeekStart).toLocaleDateString(
              "en-US",
              {
                weekday: "short",
                day: "2-digit",
                month: "short",
                year: "numeric",
              }
            )} to ${new Date(pastWeekEnd).toLocaleDateString("en-US", {
              weekday: "short",
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}`
          : "Orders"}
      </h4>

      <h4>
        {data.totalCount} {data.totalCount === 1 ? "order" : "orders"}
      </h4>
      <h2 style={{ color: "red" }}>
        {data.totalCount === 0 ? "No orders found 😢" : "  "}
      </h2>
      <button
        // on click show the dialog box
        onClick={() => setShowShoppingListDialog(true)}
        className="generate-shopping-list-button"
        style={data.totalCount === 0 ? { backgroundColor: "grey" } : {}}
        disabled={data.totalCount === 0}
      >
        Generate Shopping List
      </button>
      <ShoppingListDialog
        open={showShoppingListDialog}
        onClose={() => setShowShoppingListDialog(false)}
        orders={data.nodes}
      />
      {data.nodes.map((o) => {
        const orderUpdate = orderUpdates.find((update) => update.id === o.id);
        return (
          <div key={o.id} className="order">
            <div className="order-item">
              <span>Order Number</span> <span>{o.orderSerial}</span>
              <details>
                <summary>
                  <span>Total</span>{" "}
                  <span>
                    {o.receipt.total.amount} {o.receipt.total.currency}
                  </span>
                </summary>
                <h5>subtotal</h5>{" "}
                <p>
                  {o.receipt.subtotal.amount} {o.receipt.subtotal.currency}
                </p>
                <br />
                <h5>shipping</h5>{" "}
                <p>
                  {o.receipt.shipping?.amount} {o.receipt.shipping?.currency}
                </p>
                <br />
                <h5>discount</h5>{" "}
                <p>
                  {o.receipt.discount.amount} {o.receipt.discount.currency}
                </p>
                <br />
                <h5>Total</h5>{" "}
                <p>
                  {o.receipt.total.amount} {o.receipt.total.currency}
                </p>
              </details>
              <details>
                <summary>
                  <span>Customer</span>{" "}
                  <span>
                    {o?.customer?.firstName} {o?.customer?.lastName}
                  </span>
                </summary>
                <h5>name</h5>{" "}
                <p>
                  {o?.customer?.firstName} {o?.customer?.lastName}
                </p>
                <br />
                <h5>phone</h5> <p>{o.customer?.phone}</p>
                <br />
                <h5>email</h5> <p>{o.customer?.email}</p>
                <br />
              </details>
              <details>
                <summary>
                  <span>
                    {o?.noOfMeals} Meals for {o?.noOfPeople} People
                  </span>
                </summary>
                {o?.items?.map((i) => (
                  <div key={i?.info?.id}>
                    <h5>{i?.info?.name}</h5>{" "}
                    <p>
                      {i?.quantity} X{" "}
                      {i?.info?.price?.amount * (o.noOfPeople / 2)}
                      {i?.info?.price?.currency}
                    </p>
                    <br />
                  </div>
                ))}
              </details>
              <details>
                <summary>
                  <span>Addons</span> <span>{o?.addOns?.length} Addons</span>
                </summary>
                {o?.addOns?.map((a) => (
                  <div key={a?.id}>
                    <h5>{a?.name}</h5>{" "}
                    <p>
                      {a.measurement} {a?.unit} {a?.price?.amount}{" "}
                      {a?.price?.currency}
                    </p>
                    <br />
                  </div>
                ))}
              </details>
              <details>
                <summary>
                  <span>Address</span>{" "}
                  <span>
                    {o?.shippingAddress?.city} On{" "}
                    {new Date(o?.deliveryDate).toLocaleDateString("en-US", {
                      weekday: "short",
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}{" "}
                    At {o?.deliveryWindow}
                  </span>
                </summary>
                <h5>title</h5> <p>{o?.shippingAddress?.title}</p>
                <br />
                <h5>country</h5> <p>{o?.shippingAddress?.country}</p>
                <br />
                <h5>city</h5> <p>{o?.shippingAddress?.city}</p>
                <br />
                <h5>details</h5> <p>{o.shippingAddress?.addressDetails}</p>
                <br />
                <h5>type</h5> <p>{o.shippingAddress?.residentialComplex}</p>
                <br />
                <h5>apartment</h5> <p>{o.shippingAddress?.apartment}</p>
                <br />
                <h5>floor</h5> <p>{o.shippingAddress?.floor}</p>
                <br />
                <h5>extra</h5> <p>{o.shippingAddress?.instructions}</p>
                <br />
                {o?.shippingAddress?.location?.lat &&
                  o?.shippingAddress?.location?.lng && (
                    <>
                      <h5>location</h5>{" "}
                      <a
                        href={`https://maps.google.com/?q=${o?.shippingAddress?.location?.lat},${o?.shippingAddress?.location?.lng}`}
                      >
                        https://maps.google.com/?q=
                        {o?.shippingAddress?.location?.lat},
                        {o?.shippingAddress?.location?.lng}
                      </a>
                      <br />
                    </>
                  )}
              </details>
            </div>
            <div className="order-update">
              {/* enable update of order status, shipping status, payment status */}
              <div>
                <h5>Order Status</h5>
                <select
                  value={
                    orderUpdates.find((update) => update.id === o.id)?.status
                  }
                  onChange={(e) => {
                    const value = e.target.value as OrderStatusEnum;
                    setOrderUpdates((prev) =>
                      prev.map((update) =>
                        update.id === o.id
                          ? { ...update, status: value }
                          : update
                      )
                    );
                  }}
                >
                  {/* map the order status to the select options from OrderStatusEnum */}
                  {Object.values(OrderStatusEnum).map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
              <br />
              <div>
                <h5>Shipping Status</h5>
                <select
                  value={
                    orderUpdates.find((update) => update.id === o.id)
                      ?.shippingStatus
                  }
                  onChange={(e) => {
                    const value = e.target.value as OrderShippingStatusEnum;
                    setOrderUpdates((prev) =>
                      prev.map((update) =>
                        update.id === o.id
                          ? { ...update, shippingStatus: value }
                          : update
                      )
                    );
                  }}
                >
                  {/* map the order status to the select options from OrderStatusEnum */}
                  {Object.values(OrderShippingStatusEnum).map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
              <br />
              <div>
                <h5>Payment Status</h5>
                <select
                  value={
                    orderUpdates.find((update) => update.id === o.id)
                      ?.paymentStatus
                  }
                  onChange={(e) => {
                    const value = e.target.value as OrderPaymentStatusEnum;
                    setOrderUpdates((prev) =>
                      prev.map((update) =>
                        update.id === o.id
                          ? { ...update, paymentStatus: value }
                          : update
                      )
                    );
                  }}
                >
                  {/* map the order status to the select options from OrderStatusEnum */}
                  {Object.values(OrderPaymentStatusEnum).map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                <br />
                <button
                  className="update-button"
                  onClick={() => handleUpdate(o.id)}
                  disabled={
                    orderUpdate?.status === o.status &&
                    orderUpdate?.shippingStatus === o.shippingStatus &&
                    orderUpdate?.paymentStatus === o.paymentStatus
                  }
                  style={
                    orderUpdate?.status === o.status &&
                    orderUpdate?.shippingStatus === o.shippingStatus &&
                    orderUpdate?.paymentStatus === o.paymentStatus
                      ? { backgroundColor: "gray" }
                      : {}
                  }
                >
                  Update
                </button>
                <p>{orderUpdate?.message}</p>
              </div>
            </div>
          </div>
        );
      })}
      <div className="pagination-orders">
        <div>
          {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => setCurrentPage(pageNumber)}
              className={pageNumber === currentPage ? "active" : ""}
            >
              {pageNumber}
            </button>
          ))}
        </div>
        <div className="page-size">
          <p>Show:</p>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            <option>10</option>
            <option>20</option>
            <option>50</option>
            <option>100</option>
          </select>
          <p>Orders</p>
        </div>
      </div>
    </div>
  );
};

export default Orders;
