import { Money } from "./checkout-types";

export enum PromoCodeTypeEnum {
  PERCENTAGE = "PERCENTAGE",
  FIXED = "FIXED", // from total order or per item?
}

export enum PromoCodeStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  EXPIRED = "EXPIRED",
}

export interface TPromoCode {
  id?: string;
  type: PromoCodeTypeEnum;
  code: string;
  amount: number; // percentage or fixed amount depending on type
  status: PromoCodeStatusEnum;
  numberOfUsage?: number;
  oncePerUser?: boolean;
  usageLimit: number;
  usedBy?: number[];
  requirement?: {
    minOrderAmount?: Money;
    maxOrderAmount?: Money;
  };
  createdAt?: Date;
  updatedAt?: Date;
}

export type CreatePromoCodeDTO = {
  id?: string;
  type: PromoCodeTypeEnum;
  code: string;
  amount: number;
  status: PromoCodeStatusEnum;
  numberOfUsage?: number;
  usageLimit: number;
  oncePerUser?: boolean;
  requirement?: {
    minOrderAmount?: Money;
    maxOrderAmount?: Money;
  };
};
