import React, { useState, useEffect } from "react";
import axios from "../shared/axios";
import "./promoCodes.css";
import {
  TPromoCode,
  PromoCodeStatusEnum,
  PromoCodeTypeEnum,
  CreatePromoCodeDTO,
} from "../shared/types/coupon-types";
import { PaginatedResponse } from "../shared/types";

const PromoCodesPage: React.FC = () => {
  const [promoCodes, setPromoCodes] = useState<PaginatedResponse<TPromoCode>>({
    nodes: [],
    totalCount: 0,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      endCursor: "",
    },
  });
  const [notification, setNotification] = useState<{
    type: "success" | "error";
    message: string;
  } | null>(null);
  const [selectedPromoCode, setSelectedPromoCode] = useState<TPromoCode>({
    id: "",
    type: PromoCodeTypeEnum.PERCENTAGE,
    code: "",
    amount: 0,
    status: PromoCodeStatusEnum.ACTIVE,
    oncePerUser: false,
    usageLimit: 0,
    requirement: {
      minOrderAmount: { amount: 0, currency: "EGP" },
      maxOrderAmount: { amount: 0, currency: "EGP" },
    },
  });
  const initialFormState: TPromoCode = {
    type: PromoCodeTypeEnum.PERCENTAGE,
    code: "",
    amount: 0,
    status: PromoCodeStatusEnum.ACTIVE,
    oncePerUser: false,
    usageLimit: 0,
    requirement: {
      minOrderAmount: { amount: 0, currency: "EGP" },
      maxOrderAmount: { amount: 0, currency: "EGP" },
    },
  };

  const [formState, setFormState] = useState<TPromoCode>(initialFormState);
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormState((prevState: TPromoCode) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    // Fetch promo codes when component mounts
    axios.get("/api/promo").then((response) => {
      setPromoCodes(response.data);
    });
  }, []);

  // ... (rest of the component)

  const handleCreateUpdatePromoCode = (promoCodeData: CreatePromoCodeDTO) => {
    const method = promoCodeData.id ? axios.put : axios.post;
    const endpoint = promoCodeData.id
      ? `/api/promo/${promoCodeData.id}`
      : "/api/promo";
    promoCodeData.amount = Number(promoCodeData.amount);
    promoCodeData.usageLimit = Number(promoCodeData.usageLimit);
    method(endpoint, {
      ...promoCodeData,
      amount: Number(promoCodeData.amount),
    })
      .then((response) => {
        const updatedPromoCode: TPromoCode = response.data;
        if (promoCodeData.id) {
          // Update the list with the edited promo code
          setPromoCodes((prevState) => ({
            ...prevState,
            nodes: prevState.nodes.map((promo) =>
              promo.id === promoCodeData.id ? updatedPromoCode : promo
            ),
          }));
          setFormState(initialFormState); // Reset the form
          setNotification({
            type: "success",
            message: `Promo Code ${
              promoCodeData.id ? "updated" : "created"
            } successfully!`,
          });
        } else {
          // Add the new promo code to the list
          setPromoCodes((prevState) => ({
            ...prevState,
            nodes: [updatedPromoCode, ...prevState.nodes],
          }));
        }
        setFormState(initialFormState); // Reset the form
        setNotification({
          type: "success",
          message: `Promo Code ${
            promoCodeData.id ? "updated" : "created"
          } successfully!`,
        });
        setTimeout(() => setNotification(null), 3000); // Automatically hide the notification after 3 seconds
      })
      .catch((error) => {
        // Handle error - Show error message
        console.error("Failed to save promo code:", error);
        setNotification({
          type: "error",
          message: "Failed to save promo code. Please try again.",
        });
        setTimeout(() => setNotification(null), 3000); // Automatically hide the notification after 3 seconds
      });
  };

  // ... (rest of the component)

  const handleEditPromoCode = (promoCode: TPromoCode) => {
    delete promoCode.usedBy;
    setFormState({
      ...promoCode,
      requirement: promoCode.requirement || {
        minOrderAmount: { amount: 0, currency: "EGP" },
        maxOrderAmount: { amount: 0, currency: "EGP" },
      },
    });
  };

  return (
    <div className="promo-codes-container">
      <h1>Promo Codes</h1>
      <table className="promo-codes-table">
        <thead>
          <tr>
            <th>Code</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Once Per user?</th>
            <th>Usage Limit</th>
            <th>number of Usage</th>
            <th>Min Order Amount</th>
            <th>Max Order Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {promoCodes.nodes.map((promoCode) => (
            <tr key={promoCode.id}>
              <td>{promoCode.code}</td>
              <td>{promoCode.type}</td>
              <td>{promoCode.amount}</td>
              <td>{promoCode.status}</td>
              <td>{promoCode?.oncePerUser ? "Yes" : "No"}</td>
              <td>{promoCode.usageLimit}</td>
              <td>{promoCode.numberOfUsage}</td>
              <td>{promoCode.requirement?.minOrderAmount?.amount || "-"}</td>
              <td>{promoCode.requirement?.maxOrderAmount?.amount || "-"}</td>
              <td>
                <button onClick={() => handleEditPromoCode(promoCode)}>
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="promo-code-form">
        <label>
          Type:
          <select
            name="type"
            value={formState.type}
            onChange={handleInputChange}
          >
            {Object.values(PromoCodeTypeEnum).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </label>
        <label>
          Code:
          <input
            type="text"
            name="code"
            value={formState.code}
            placeholder="Code"
            onChange={handleInputChange}
          />
        </label>
        <label>
          Amount:
          <input
            type="number"
            name="amount"
            value={formState.amount}
            placeholder="Amount"
            onChange={handleInputChange}
          />
        </label>
        <label>
          Status:
          <select
            name="status"
            value={formState.status}
            onChange={handleInputChange}
          >
            {Object.values(PromoCodeStatusEnum).map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </label>
        <label>
          Usage Limit:
          <input
            type="number"
            name="usageLimit"
            value={formState.usageLimit}
            placeholder="Usage Limit"
            onChange={handleInputChange}
          />
        </label>
        <label>
          minOrderAmount:
          <input
            type="number"
            name="minOrderAmount"
            value={formState.requirement?.minOrderAmount?.amount}
            placeholder="Min Order Amount"
            onChange={handleInputChange}
          />
        </label>
        <label>
          maxOrderAmount:
          <input
            type="number"
            name="maxOrderAmount"
            value={formState.requirement?.maxOrderAmount?.amount}
            placeholder="Max Order Amount"
            onChange={handleInputChange}
          />
        </label>
        <span style={{ display: "flex" }}>
          <label>Once Per User:</label>
          <input
            style={{ width: "20px" }}
            type="checkbox"
            name="oncePerUser"
            checked={formState.oncePerUser}
            onChange={(e) =>
              setFormState((prevState) => ({
                ...prevState,
                oncePerUser: e.target.checked,
              }))
            }
          />
        </span>
        <button onClick={() => handleCreateUpdatePromoCode(formState)}>
          Create/Update
        </button>
      </div>
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default PromoCodesPage;
