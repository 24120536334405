import React, { useState, ChangeEvent, FormEvent } from "react";
import "./Utensils.css";
import axios from "../shared/axios";

export enum Locale {
  EN = "en",
  AR = "ar",
}

export interface UtensilTranslationDto {
  name: string;
  slug: string;
  locale: Locale;
}

export interface CreateUtensilDto {
  name: string;
  slug: string;
  imageSrc: string;
  imageCaption: string;
  translations: UtensilTranslationDto[];
}

const UtensilCreationForm: React.FC = () => {
  const [formData, setFormData] = useState<CreateUtensilDto>({
    name: "",
    slug: "",
    imageSrc: "",
    imageCaption: "",
    translations: [],
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleTranslationChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const newTranslations = [...formData.translations];
    newTranslations[index] = {
      ...newTranslations[index],
      [event.target.name]: event.target.value,
    };
    setFormData({
      ...formData,
      translations: newTranslations,
    });
  };

  const handleAddTranslation = () => {
    setFormData({
      ...formData,
      translations: [
        ...formData.translations,
        { name: "", slug: "", locale: Locale.EN },
      ],
    });
  };

  const handleRemoveTranslation = (index: number) => {
    setFormData({
      ...formData,
      translations: formData.translations.filter((_, i) => i !== index),
    });
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    axios
      .post(`/api/utensils`, {
        ...formData,
        images: [
          {
            src: formData.imageSrc,
            caption: formData.imageCaption,
          },
        ],
      })

      .then((response) => {
        // console.log("Utensil created:", response.data);
        window.location.reload();
      })
      .catch((error) => console.error("Error creating utensil:", error));
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <h1>
        <span className="title">Create Utensil</span>
      </h1>
      <label className="label">Name:</label>
      <input
        type="text"
        name="name"
        onChange={handleChange}
        className="input"
      />
      <label className="label">Slug:</label>
      <input
        type="text"
        name="slug"
        onChange={handleChange}
        className="input"
      />
      <label className="label">Image:</label>
      <div
        className="images"
        style={{
          border: "1px solid ",
          borderColor: "#e2d2d2",
          borderRadius: "5px",
          padding: "5px",
          width: "90%",
          margin: "0px 0px 10px 5px ",
        }}
      >
        <label className="label">src:</label>
        <input
          type="text"
          name="imageSrc"
          onChange={handleChange}
          className="input"
        />
        <label className="label">caption:</label>
        <input
          type="text"
          name="imageCaption"
          onChange={handleChange}
          className="input"
        />
      </div>
      <label>Translation</label>
      {formData.translations.map((translation, index) => (
        <div key={index} className="translation-block">
          <label className="label">Name:</label>
          <input
            type="text"
            name="name"
            value={translation.name}
            onChange={(event) => handleTranslationChange(index, event)}
            className="input"
          />
          <label className="label">Slug:</label>
          <input
            type="text"
            name="slug"
            value={translation.slug}
            onChange={(event) => handleTranslationChange(index, event)}
            className="input"
          />

          <label className="label">Locale:</label>
          <select
            name="locale"
            value={translation.locale}
            onChange={(event) => handleTranslationChange(index, event)}
            className="select"
          >
            <option value={Locale.AR}>AR</option>
          </select>
          <button
            type="button"
            onClick={() => handleRemoveTranslation(index)}
            className="remove-button"
          >
            Remove
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddTranslation}
        className="add-button"
      >
        Add Translation
      </button>
      <button type="submit" className="submit-button">
        Submit
      </button>
    </form>
  );
};

export { UtensilCreationForm as Utensil };
