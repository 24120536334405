import React, { useState, ChangeEvent, FormEvent } from "react";
import "./Category.css";
import axios from "../shared/axios";

export enum Locale {
  AR = "ar",
}

export interface CategoryTranslationDto {
  name: string;
  slug: string;
  locale: Locale;
}

export interface CreateCategoryDto {
  type: string;
  name: string;
  slug: string;
  translations: CategoryTranslationDto[];
}

const CategoryCreationForm: React.FC = () => {
  const [formData, setFormData] = useState<CreateCategoryDto>({
    type: "",
    name: "",
    slug: "",
    translations: [],
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleTranslationChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const newTranslations = [...formData.translations];
    newTranslations[index] = {
      ...newTranslations[index],
      [event.target.name]: event.target.value,
    };
    setFormData({
      ...formData,
      translations: newTranslations,
    });
  };

  const handleAddTranslation = () => {
    setFormData({
      ...formData,
      translations: [
        ...formData.translations,
        { name: "", slug: "", locale: Locale.AR },
      ],
    });
  };

  const handleRemoveTranslation = (index: number) => {
    setFormData({
      ...formData,
      translations: formData.translations.filter((_, i) => i !== index),
    });
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    axios
      .post(` /api/categories`, { formData })
      .then((response) => {
        // console.log("Category created:", response.data);
        window.location.reload();
      })
      .catch((error) => console.error("Error creating category:", error));
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <h1>
        <span className="title">Create Category</span>
      </h1>
      <label className="label">Type:</label>
      <input
        type="text"
        name="type"
        onChange={handleChange}
        className="input"
      />
      <label className="label">Name:</label>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        className="input"
      />
      <label className="label">Slug:</label>
      <input
        type="text"
        name="slug"
        onChange={handleChange}
        className="input"
      />
      <label>Translation</label>
      {formData.translations.map((translation, index) => (
        <div key={index} className="translation-block">
          <label className="label">Name:</label>
          <input
            type="text"
            name="name"
            value={translation.name}
            onChange={(event) => handleTranslationChange(index, event)}
            className="input"
          />
          <label className="label">Slug:</label>
          <input
            type="text"
            name="slug"
            value={translation.slug}
            onChange={(event) => handleTranslationChange(index, event)}
            className="input"
          />
          <label className="label">Locale:</label>
          <select
            name="locale"
            value={translation.locale}
            onChange={(event) => handleTranslationChange(index, event)}
            className="select"
          >
            <option value={Locale.AR}>AR</option>
          </select>
          <button
            type="button"
            onClick={() => handleRemoveTranslation(index)}
            className="remove-button"
          >
            Remove
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddTranslation}
        className="add-button"
      >
        Add Translation
      </button>
      <button type="submit" className="submit-button">
        Submit
      </button>
    </form>
  );
};

export { CategoryCreationForm as Category };
