import React, { useCallback, useEffect, useState } from "react";
import axios from "../shared/axios";
import {
  IngredientInfoDto,
  RecipeStatusEnumDescription,
  TRecipe,
  RecipeStatusEnum,
  CookingDifficulty,
} from "../shared/types";
import EditorModal from "../shared/components/EditorModal/EditorModal";

import "./Recipes.css";
import EditRecipeForm from "../shared/components/EditRecipe/EditRecipeForm";
import EditRecipeIngredients from "../shared/components/EditRecipe/EditRecipeIngredients";
import EditRecipeCategories from "../shared/components/EditRecipe/EditRecipeCategories";
import EditRecipeTags from "../shared/components/EditRecipe/EditRecipeTags";
import { parseTimeToMins } from "../shared/utils/parse-time";

interface RecipesPageProps {}

const getStatusColor = (status: RecipeStatusEnum) => {
  switch (status) {
    case RecipeStatusEnum.DRAFT:
      return "gray";
    case RecipeStatusEnum.ACTIVE:
      return "green";
    case RecipeStatusEnum.ARCHIVED:
      return "gray";
    case RecipeStatusEnum.VOTE_PHASE:
      return "orange";
  }
};

const getDifficultyColor = (difficulty: CookingDifficulty) => {
  switch (difficulty) {
    case CookingDifficulty.VERY_EASY:
      return "lightgreen";
    case CookingDifficulty.EASY:
      return "green";
    case CookingDifficulty.MEDIUM:
      return "orange";
    case CookingDifficulty.HARD:
      return "red";
    case CookingDifficulty.VERY_HARD:
      return "darkred";
  }
};

const RecipesPage: React.FC<RecipesPageProps> = () => {
  const [data, setData] = useState<TRecipe[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [recipeToEdit, setRecipeToEdit] = useState<TRecipe>();
  const [ingredientToEdit, setIngredientToEdit] = useState<IngredientInfoDto>();
  const [updateRecipe, setUpdateRecipe] = useState(false);
  const [updateIngredients, setUpdateIngredients] = useState(false);
  const [updateCategories, setUpdateCategories] = useState(false);
  const [updateTags, setUpdateTags] = useState(false);

  const onCloseModal = () => {
    setOpenModal(false);
    setRecipeToEdit(undefined);
    setIngredientToEdit(undefined);
    setUpdateCategories(false);
    setUpdateTags(false);
    setUpdateIngredients(false);
    setUpdateRecipe(false);
  };

  const refetch = useCallback(async () => {
    await axios(
      `/api/recipes?filter={"populateResponse":true, "status": "ALL"}`
    ).then((response) => {
      const data: TRecipe[] = response.data?.nodes;
      let recipeId = "";
      setData(data);
      setRecipeToEdit((prev) => {
        recipeId = prev?.id || "";
        return data.find((i) => i.id === prev?.id);
      });
      setIngredientToEdit((prev) => {
        const recipe = data.find((i) => i.id === recipeToEdit?.id);
        return recipe?.ingredients.find((i) => i.info.id === prev?.info.id);
      });
    });
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className={`recipes ${openModal && "open"}`}>
      {data?.map((recipe) => (
        <div key={recipe.id} className="recipe">
          <div className="recipe-item">
            <img
              alt=""
              className="recipe-image"
              src={recipe.images?.[0]?.src}
            />
            <h5>Name</h5> <p>{recipe.name}</p>
            <br />
            <h5>Arabic Name</h5> <p>{recipe.translations[0].name}</p>
            <br />
            <h5>Status</h5>{" "}
            <p style={{ color: getStatusColor(recipe.status) }}>
              {recipe.status}
            </p>{" "}
            <p style={{ color: getStatusColor(recipe.status) }}>
              {RecipeStatusEnumDescription[recipe.status]}
            </p>
            <br />
            <h5>Price</h5> <p>{recipe.price.amount} EGP</p>
            <br />
            <h5>Difficulty</h5>{" "}
            <p style={{ color: getDifficultyColor(recipe.cookingDifficulty) }}>
              {recipe.cookingDifficulty}
            </p>
            <br />
            <h5>Cooking Time</h5>{" "}
            <p>{parseTimeToMins(recipe.cookingTime)} Min</p>
            <br />
            <h5>Slug</h5> <p>{recipe.slug}</p>
            <br />
            {recipe.upVotes && (
              <>
                <h5>Up Votes</h5>
                <p>{recipe.upVotes}</p>
                <br />
              </>
            )}
            <h5>Ingredients</h5>
            <p>
              {recipe.ingredients.map((i) => (
                <span
                  className="ingredient-name"
                  key={i.info.name}
                  onClick={() => {
                    setOpenModal(true);
                    setRecipeToEdit(recipe);
                    setIngredientToEdit(i);
                    setUpdateIngredients(true);
                  }}
                >
                  {i.info.name}
                </span>
              ))}
            </p>
            <br />
            <h5>Categories</h5>
            <p>
              {recipe.categories.map((c) => (
                <span
                  className="ingredient-name"
                  key={c.id}
                  onClick={() => {
                    setOpenModal(true);
                    setRecipeToEdit(recipe);
                    setUpdateCategories(true);
                  }}
                >
                  {c.name}
                </span>
              ))}
            </p>
            <br />
            <h5>Tags</h5>
            <p>
              {(recipe.tags || []).map((t) => (
                <span
                  className="ingredient-name"
                  key={t}
                  onClick={() => {
                    setOpenModal(true);
                    setRecipeToEdit(recipe);
                    setUpdateTags(true);
                  }}
                >
                  {t}
                </span>
              ))}
            </p>
            <br />
            <div className="recipe-footer">
              <button
                className="update-button"
                onClick={() => {
                  setOpenModal(true);
                  setRecipeToEdit(recipe);
                  setUpdateTags(true);
                }}
              >
                Edit Tags
              </button>

              <button
                className="update-button"
                onClick={() => {
                  setOpenModal(true);
                  setRecipeToEdit(recipe);
                  setUpdateCategories(true);
                }}
              >
                Edit Categories
              </button>

              <button
                className="update-button"
                onClick={() => {
                  setOpenModal(true);
                  setRecipeToEdit(recipe);
                  setIngredientToEdit(recipe.ingredients[0]);
                  setUpdateIngredients(true);
                }}
              >
                Edit Ingredients
              </button>

              <button
                className="update-button"
                onClick={() => {
                  setOpenModal(true);
                  setUpdateRecipe(true);
                  setRecipeToEdit(recipe);
                }}
              >
                Edit Recipe
              </button>
            </div>
          </div>
        </div>
      ))}
      <EditorModal open={openModal} onClose={onCloseModal}>
        {recipeToEdit && updateRecipe && (
          <EditRecipeForm recipe={recipeToEdit} refetch={refetch} />
        )}
        {recipeToEdit && updateIngredients && (
          <EditRecipeIngredients
            recipe={recipeToEdit}
            ingredients={recipeToEdit?.ingredients!}
            defaultIngredient={ingredientToEdit}
            refetch={refetch}
          />
        )}
        {updateCategories && recipeToEdit && (
          <EditRecipeCategories recipe={recipeToEdit} refetch={refetch} />
        )}
        {updateTags && recipeToEdit && (
          <EditRecipeTags recipe={recipeToEdit} refetch={refetch} />
        )}
      </EditorModal>
    </div>
  );
};

export default RecipesPage;
