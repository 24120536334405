import axios from "axios";

// export const BASE_URL ="https://canary-api.tryfreshkit.com";
export const BASE_URL = "https://api.tryfreshkit.com";

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
  },
});

export default instance;
