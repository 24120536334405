// create a dialog to show when the user clicks on the shopping list button
// this dialog should contain a list of selectable(multi-select) of the ingredients fields that the user should choose to add to the shopping list csv and a button to download the csv.
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
} from "@material-ui/core";
import { IngredientCategory, OrderStatusEnum, TOrder } from "../shared/types";
import axios from "../shared/axios";

interface ShoppingListDialogProps {
  open: boolean;
  onClose: () => void;
  orders: TOrder[];
}

const csvTotalHeaders = [
  "Name (en)",
  "Name (ar)",
  "Marketing Name",
  "Category",
  "Brand",
  "Servings",
  "Sum",
  "Measurement",
  "Unit",
  "On Hand",
  "Quantity to Buy",
];

type SelectedHeadersMap = {
  [key: string]: boolean;
};

const ShoppingListDialog: React.FC<ShoppingListDialogProps> = ({
  open,
  onClose,
  orders,
}) => {
  const [csvHeaders, setCsvHeaders] = useState(csvTotalHeaders);
  const [selectedHeaders, setSelectedHeaders] = useState(csvTotalHeaders);
  const [selectedHeadersMap, setSelectedHeadersMap] =
    useState<SelectedHeadersMap>(
      csvTotalHeaders.reduce((acc, curr) => ({ ...acc, [curr]: true }), {})
    );
  orders = orders.filter(
    (order) => order.status === OrderStatusEnum.SUCCESSFUL
  );
  const generateShoppingList = async (orders: TOrder[]) => {
    // extract the meals from orders and the numberOfServings for each meal [{id: string, numberOfServings: number}]
    const meals = orders
      .map((order) =>
        order.items
          .map((item) => {
            // if quantity is higher than one return this object multiple times = quantity {          id: item.info.id,          numberOfServings: order.noOfPeople,        }
            return Array.from({ length: item.quantity }, () => ({
              id: item.info.id,
              numberOfServings: order.noOfPeople,
            }));
          })
          .flat()
      )
      .flat();
    // get the number of recipes ordered in an array of object of name, quantity
    const recipesSum = orders
      .map(
        (order) =>
          order.items.map((item) => ({
            name: item.info.name,
            quantity: item.quantity,
          })) as { name: string; quantity: number }[]
      )
      .flat();
    // sum the recipes with the same name
    const recipesSum2 = recipesSum.reduce((acc, curr) => {
      const index = acc.findIndex((item) => item.name === curr.name);
      if (index === -1) {
        return [...acc, curr];
      } else {
        return [
          ...acc.slice(0, index),
          {
            ...acc[index],
            quantity: acc[index].quantity + curr.quantity,
          },
          ...acc.slice(index + 1),
        ];
      }
    }, [] as typeof recipesSum);
    const addOns = orders
      .map((order) =>
        order.addOns.map((addOn) => ({
          "name (en)": addOn.name,
          "name (ar)": addOn.translations[0].name,
          measurement: addOn.measurement,
          quantity: 1,
          unit: "bottle",
        }))
      )
      .flat();
    // sum the addons with the same name
    const addOnsSum = addOns.reduce((acc, curr) => {
      const index = acc.findIndex(
        (item) => item["name (en)"] === curr["name (en)"]
      );
      if (index === -1) {
        return [...acc, curr];
      } else {
        return [
          ...acc.slice(0, index),
          {
            ...acc[index],
            quantity: acc[index].quantity + curr.quantity,
          },
          ...acc.slice(index + 1),
        ];
      }
    }, [] as typeof addOns);
    const response = await axios.post("/api/recipes/shopping-list", {
      boughtRecipes: meals,
    });
    const shoppingList: {
      "name (en)": string;
      "name (ar)": string;
      brand: string;
      "Marketing Name": string;
      Category: IngredientCategory;
      "on Hand": number;
      servings: number;
      sum: number;
      quantity: number;
      measurement: string;
      unit: string;
    }[] = response.data.sort(
      // category
      (a: any, b: any) =>
        (b.Category || "").localeCompare(a.Category || "") ||
        // type
        // a.type.localeCompare(b.type) ||
        // name
        a["name (en)"].localeCompare(b["name (en)"])
    );
    // generate a csv file from the shopping list
    // Convert shopping list to CSV format depending on the selected headers
    const headers = selectedHeaders.join(",");

    const csvData = [
      ["No Of Orders", String(orders.length)],
      [""],
      ["Recipes"],
      ["name", "Quantity"],
      ...recipesSum2.map((item) => [item.name, item.quantity]),
      [""],
      [headers],
    ]
      .concat(
        shoppingList.map((item) =>
          selectedHeaders.map((header) => {
            switch (header) {
              case "Name (en)":
                return item["name (en)"] || "-";
              case "Name (ar)":
                return item["name (ar)"] || "-";
              case "Marketing Name":
                return item["Marketing Name"].replace(",", "") || "-";
              case "Category":
                return item.Category || "-";
              case "Brand":
                return item.brand.replace(",", "") || "-";
              case "Servings":
                return item.servings || "-";
              case "Quantity to Buy":
                return item.quantity || "-";
              case "Unit":
                return item.unit || "-";
              case "Measurement":
                return item.measurement || "-";
              case "Sum":
                return item.sum || "-";
              case "On Hand":
                return item["on Hand"] || "-";
              default:
                return "";
            }
          })
        )
      )
      .concat([[""], ["Add Ons"]])
      .concat([
        ["name (en)", "name (ar)", "quantity", "measurement", "unit", "", ""],
      ])
      .concat(
        addOnsSum.map((item) => [
          item["name (en)"],
          item["name (ar)"],
          item.quantity.toString(),
          item.measurement.toString(),
          item.unit,
          "",
          "",
        ])
      );
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    // show the file in a new tab and allow the user to download it
    // Generate a blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create an anchor element and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank"; // Open in a new tab
    a.download = "shopping-list.csv";
    a.click();
    URL.revokeObjectURL(url); // Clean up
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select ingredients to add to shopping list</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormGroup>
            {csvHeaders.map((header) => (
              <FormControlLabel
                style={{
                  cursor: "pointer",
                  margin: 0,
                  flexDirection: "row",
                }}
                key={header}
                control={
                  <Checkbox
                    checked={selectedHeadersMap[header]}
                    onChange={(e) => {
                      setSelectedHeadersMap({
                        ...selectedHeadersMap,
                        [header]: e.target.checked,
                      });
                      if (e.target.checked) {
                        setSelectedHeaders([...selectedHeaders, header]);
                      } else {
                        setSelectedHeaders(
                          selectedHeaders.filter((item) => item !== header)
                        );
                      }
                    }}
                    name={header}
                  />
                }
                label={header}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            generateShoppingList(orders);
            onClose();
          }}
          color="primary"
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShoppingListDialog;
