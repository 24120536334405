import React, { useState } from "react";
import { IngredientInfoDto, TRecipe } from "../../types";
import axios from "../../axios";

interface FormValues {
  unit: string;
  [key: string]: string;
}

interface EditRecipeIngredientFormProps {
  recipe: TRecipe;
  ingredient: IngredientInfoDto;
  ingredients: IngredientInfoDto[];
  refetch: () => Promise<void>;
}

const EditRecipeIngredientForm: React.FC<EditRecipeIngredientFormProps> = ({
  recipe,
  ingredient,
  ingredients,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.target as HTMLFormElement);
    const formValues = Object.fromEntries(formData) as unknown as FormValues;
    const quantityKeys = Object.keys(formValues).filter((key) =>
      key.startsWith("quantityFor")
    );
    const newIngredient = {
      info: ingredient.info.id,
      unit: formValues.unit,
      quantity: quantityKeys.map((key) => ({
        numberOfServings: Number(key.split("_")[1]),
        value: Number(formValues[key]),
      })),
    };
    const newIngredients = ingredients.map((i) =>
      i.info.id === newIngredient.info
        ? newIngredient
        : { ...i, info: i.info.id }
    );
    await axios(`/api/recipes/${recipe.id}`, {
      method: "PUT",
      data: { ingredients: newIngredients },
    });
    await refetch();
    setSaved(true);
    setLoading(false);
    setTimeout(() => setSaved(false), 3000);
  };

  return (
    <form onSubmit={handleSubmit} className="form" style={{ maxWidth: "100%" }}>
      <h1 className="title">
        {saved ? "Saved" : loading ? "Loading..." : "Edit Recipe Ingredient"}
      </h1>
      <label className="label">Unit:</label>
      <select name="unit" className="select" defaultValue={ingredient.unit}>
        <option value="gram">Grams</option>
        <option value="ml">m-Liter</option>
        <option value="liter">Liter</option>
        <option value="sachet">Sachet</option>
      </select>
      {ingredient.quantity.map((quantity) => (
        <div key={quantity.numberOfServings}>
          <label className="label">
            Quantity needed for {quantity.numberOfServings} people:
          </label>
          <input
            type="text"
            name={`quantityFor_${quantity.numberOfServings}`}
            className="input"
            defaultValue={quantity.value}
          />
        </div>
      ))}
      <button disabled={saved || loading} className="submit-button">
        Save
      </button>
    </form>
  );
};

export default EditRecipeIngredientForm;
