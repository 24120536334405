export function getCurrentWeekStartEnd(date: Date) {
  const now = date;
  const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
  const diffToThursday = (dayOfWeek < 4 ? -3 : 4) - dayOfWeek; // Difference to Thursday

  const weekStart = new Date(now);
  weekStart.setDate(now.getDate() + diffToThursday);
  weekStart.setHours(0, 0, 0, 0); // Set time to 00:00:00.000

  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6);
  weekEnd.setHours(23, 59, 59, 999); // Set time to 23:59:59.999

  return { weekStart: weekStart.getTime(), weekEnd: weekEnd.getTime() };
}

export function getPastWeekStartEnd(date: Date) {
  const now = date;

  // use getCurrentWeekStartEnd to get the start and end of the current week
  const { weekEnd, weekStart } = getCurrentWeekStartEnd(now);

  return {
    pastWeekStart: weekStart - 7 * 24 * 60 * 60 * 1000, // d, h, m, s, ms (subtract 7 days)
    pastWeekEnd: weekEnd - 7 * 24 * 60 * 60 * 1000, // d, h, m, s, ms (subtract 7 days)
  };
}

const firstWeek = "2023-08-01";
// using the firstWeek defined, get the current week number, by computing how many weeks have passed since the first week
export function getCurrentWeekNumber() {
  const today = new Date();
  const firstWeekDate = new Date(firstWeek);
  const diffTime = Math.abs(today.getTime() - firstWeekDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffWeeks = Math.ceil(diffDays / 7);
  return diffWeeks;
}

// reverse the last getCurrentWeekNumber, given the week number, get the date of the first day of the week
export function getWeekStartDate(weekNumber: number) {
  const firstWeekDate = new Date(firstWeek);
  const weekStartDate = new Date(firstWeekDate);
  weekStartDate.setDate(firstWeekDate.getDate() + (weekNumber - 1) * 7);
  return weekStartDate;
}
