import { TAddress } from "./account-types";
import {
  ShippingRatesEnum,
  TCartItem,
  TCustomer,
  TReceipt,
} from "./cart-types";
import { IMoney, TAddOnIngredient } from "./catalog-types";
import { Money } from "./checkout-types";
import { TPromoCode } from "./coupon-types";

export enum OrderStatusEnum {
  PROCESSING = "PROCESSING", // order created but not paid yet (for online payment)
  SUCCESSFUL = "SUCCESSFUL", // order successful
  CANCELLED = "CANCELLED", // order cancelled by user
}

export enum OrderShippingStatusEnum {
  PENDING = "PENDING", // initial state, order received
  SHIPPED = "SHIPPED", // order shipped
  DELIVERED = "DELIVERED", // order delivered
}

export enum OrderPaymentStatusEnum {
  PROCESSING = "PROCESSING",
  PAID = "PAID",
  REFUNDED = "REFUNDED",
  UNPAID = "UNPAID",
  FAILED = "FAILED",
}

export enum PaymentMethodEnum {
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
  ONLINE_PAYMENT = "ONLINE_PAYMENT",
}

export type TOrder = {
  id: string;
  userId: number;
  orderSerial: number;
  noOfMeals: number;
  noOfPeople: number;
  items: TCartItem[];
  addOns: TAddOnIngredient[];
  receipt: TReceipt;
  promoCode: string;
  promoCodeSnapshot: TPromoCode;
  status: OrderStatusEnum;
  paymentMethod: PaymentMethodEnum;
  paymentStatus: OrderPaymentStatusEnum;
  shippingStatus: OrderShippingStatusEnum;
  shippingAddress?: TAddress;
  shippingCost: Money;
  shippingRate: ShippingRatesEnum;
  customer: TCustomer;
  isRecurring?: boolean;
  deliveryWindow: string;
  deliveryDate: string;
  tags: string[];
  createdAt?: Date;
  updatedAt?: Date;
};
