/**
 * SHARED
 */

import { Money } from "./checkout-types";
export enum IngredientCategory {
  GRAINS = "GRAINS",
  FRUITS = "FRUITS",
  VEGETABLES = "VEGETABLES",
  MEAT = "MEAT",
  SEAFOOD = "SEAFOOD",
  POULTRY = "POULTRY",
  DAIRY = "DAIRY",
  SACHETS = "SACHETS",
  SPICES = "SPICES",
  BAKERY = "BAKERY",
  OILS = "OILS",
  HERBS = "HERBS",
  OTHERS = "OTHERS",
}

export interface PageInfo {
  startCursor: string;
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface PaginatedResponse<T> {
  nodes: T[];
  pageInfo: PageInfo;
  totalCount: number;
}

export enum Locale {
  EN = "en",
  AR = "ar",
}

export interface Image {
  src: string;
  caption?: string;
}

export interface IMoney {
  amount: number;
  currency: string;
}

export interface Seo {
  name: string;
  description?: string;
}

export interface Calories {
  value: number;
  unit: string;
}

export enum CookingDifficulty {
  VERY_EASY = "VERY_EASY",
  EASY = "EASY",
  MEDIUM = "MEDIUM",
  HARD = "HARD",
  VERY_HARD = "VERY_HARD",
}

export interface Nutrition {
  type?: string;
  name: string;
  amount: number;
  unit: string;
}

/**
 * CATEGORIES
 */

export interface CategoryTranslationDto {
  name: string;
  slug: string;
  locale: Locale;
}

export interface CategoryDto {
  id: string;
  type: string;
  name: string;
  slug: string;
  translations: CategoryTranslationDto[];
}

/**
 * INGREDIENTS
 */

export interface IngredientTranslationDto {
  name: string;
  slug: string;
  locale: Locale;
}

export interface IngredientDto {
  id: string;
  name: string;
  slug: string;
  type: string;
  measurement: number;
  unit: string;
  price: Money;
  images: { src: string; caption?: string }[];
  translations: IngredientTranslationDto[];
  onHand: number;
  category: IngredientCategory;
  marketingName: string;
  brand: string;
}

export interface TAddOnIngredient extends IngredientDto {}

/**
 * UTENSILS
 */

export interface UtensilTranslationDto {
  name: string;
  slug: string;
  locale: Locale;
}
export interface UtensilDto {
  id: string;
  name: string;
  images: { src: string; caption?: string }[];
  translations: UtensilTranslationDto[];
}

/**
 * RECIPES
 */

export interface CookingInstructionTranslation {
  index: number;
  text: string;
}

export enum RecipeStatusEnum {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  VOTE_PHASE = "VOTE_PHASE",
}

export enum RecipeStatusEnumDescription {
  DRAFT = "Not Shown to Users",
  ACTIVE = "Shown to Users and Can be Ordered",
  ARCHIVED = "Not Shown to Users",
  VOTE_PHASE = "Shown to Users and Cannot be Ordered",
}
export interface QuantityDto {
  numberOfServings: number;
  value: number;
}

export interface IngredientInfoDto {
  info: IngredientDto;
  quantity: QuantityDto[];
  unit: string;
}

export interface VideoDto {
  link: string;
  caption?: string;
}

export interface TRecipe {
  id: string;
  name: string;
  status: RecipeStatusEnum;
  slug: string;
  description: string;
  prepTime: string;
  cookingTime: string;
  price: IMoney;
  seo: Seo;
  calories: Calories;
  cookingDifficulty: CookingDifficulty;
  images: Image[];
  tags?: string[];
  nutrition: Nutrition[];
  categories: CategoryDto[];
  ingredients: IngredientInfoDto[];
  videoLink: string;
  cardLink: string;
  cookingInstructions: CookingInstructionDto[];
  utensils: UtensilDto[];
  translations: RecipeTranslation[];
  upVotes: number;
}

export type TPaginatedRecipes = PaginatedResponse<TRecipe>;

export type RecipeFilter = {
  populateResponse?: boolean;
  ids?: string[];
  sortBy?: string;
  sortOrder?: string;
};

export interface IngredientInfo {
  info: string; // reference to Ingredient
  value: number;
  value2: number;
  unit: string;
}
export interface Nutrition {
  type?: string;
  name: string;
  amount: number;
  unit: string;
}

export const PREFERENCES = [
  {
    value: "Meat & Veggies",
    label: "Meat & Veggies",
  },
  {
    value: "Family Friendly",
    label: "Family Friendly",
  },
  {
    value: "Quick & Easy",
    label: "Quick & Easy",
  },
  {
    value: "Pescatarian",
    label: "Pescatarian",
  },
];

export interface Price {
  amount: number;
  currencyCode: string;
}

export interface Seo {
  name: string;
  description?: string;
}

export interface Calories {
  value: number;
  unit: string;
}

export interface IMoney {
  amount: number;
  currency: string;
}
export interface CookingInstructionTranslation {
  index: number;
  text: string;
}
export interface RecipeTranslation {
  name: string;
  description: string;
  seo: Seo;
  locale: string;
}
export interface Image {
  src: string;
  caption?: string;
}
export interface FormData {
  name: string;
  status: RecipeStatusEnum;
  slug: string;
  description: string;
  prepTime: string;
  cookingTime: string;
  amount: number;
  seoName: string;
  seoDescription: string;
  caloriesValue: number;
  caloriesUnit: string;
  cookingDifficulty: CookingDifficulty;
  imageSrc: string;
  imageCaption: string;
  tags?: string[];
  nutrition: Nutrition[];
  categories: string[];
  ingredients: IngredientInfo[];
  videoLink: string;
  cardLink: string;
  cookingInstructions: CookingInstructionDto[];
  utensils: string[];
  translations?: RecipeTranslation[];
}
export interface CookingInstructionDto {
  index: number;
  text: string;
  ingredients: string[];
  utensils: string[];
  timers?: string;
  instructionImageSrc?: string;
  instructionImageCaption?: string;
  translations: CookingInstructionTranslation[];
}
